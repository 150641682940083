import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEnvelopeOpenText, FaSearch, FaTimes } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import dates from '~/utils/dates';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import LogBox from '~/components/Boxs/Mailers/Logs';
import { FormReport, FormSalesOffAndBkpEnergy, FormCceeFees } from './Form';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';

const AdminMailer: React.FC<any> = () => {
    const navigate                = useNavigate();
    const [ready, setReady]       = useState<Boolean>(false);
    const [search, setSearch]     = useState('');
    const [logs, setLogs]         = useState<any[]>([]);
    const [logsList, setLogsList] = useState<any[]>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let logsFilter = logsList.filter((el: any) =>
            el.typeName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.dateRef && el.dateRef.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        // Set Datas
        setSearch(data);
        setLogs(logsFilter);
    }

    function openModal(modal: any) {
        let openModal = false;

        // Define Modal
        Modal.setModalOpen(false);

        switch (modal) {
            case 'reportMailer':
                Modal.setModalClass('mreport');
                Modal.setModalTitle('Gerenciador de Envio de Relatórios');
                Modal.setModalBody(<FormReport setReadyPage={setReady} />)
                openModal = true;
                break;

            case 'salesOffMailer':
                Modal.setModalClass('msalesoffbkpenergy');
                Modal.setModalTitle('Gerenciador de Envio de Garantia Financeira');
                Modal.setModalBody(<FormSalesOffAndBkpEnergy type="salesOff" setReadyPage={setReady} />)
                openModal = true;
                break;

            case 'bkpEnergyMailer':
                Modal.setModalClass('msalesoffbkpenergy');
                Modal.setModalTitle('Gerenciador de Envio de Energia de Reserva');
                Modal.setModalBody(<FormSalesOffAndBkpEnergy type="bkpEnergy" setReadyPage={setReady} />)
                openModal = true;
                break;

            case 'cceeFeesMailer':
                Modal.setModalClass('mcceefees');
                Modal.setModalTitle('Gerenciador de Envio de Taxa CCEE');
                Modal.setModalBody(<FormCceeFees setReadyPage={setReady} />)
                openModal = true;
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        // Open Modal
        if (openModal) Modal.setModalOpen(true)
    }

    async function getLogs () {
        await api.get(`mailers/logs`, {headers: { Authorization: user.token }}).then(resp => {
            let data: any = [];
            let hash      = '';
            let item: any = null;
            resp.data.map((el: any, i: number) => {
                if (hash!==el.hash) {
                    if (hash!=='') data.push(item);
                    hash = el.hash;

                    // Treat Reference Date
                    let baseRef  = dates.getDate(el.ref);
                    let dateRef  = dates._getNameMonth(baseRef.month-1) + ' de ' + baseRef.year;
                    let typeName = treatType(el.type);

                    item = {
                        hash: el.hash,
                        type: el.type,
                        typeName,
                        dateRef,
                        date: dates.getDateFormatted(el.date, 'dd-mm-yyyy HH:MM'),
                        user: el.user.name,
                        data: []
                    }
                }

                let infos = {
                    id: el.id,
                    name: el.name,
                    mail: el.mail,
                    unit: el.unit.name,
                    status: el.status,
                    message: el.message
                };

                item.data.push(infos);
                if ((i+1)===resp.data.length) data.push(item);
            })

            setLogs(data)
            setLogsList(data)
            setReady(true)
        });
    }

    function treatType (type: string) {
        switch (type) {
            case 'report':
                return 'Relatório de Economia';
            case 'salesOff':
                    return 'Informativo de Garantia Financeira';
            case 'bkpEnergy':
                    return 'Informativo de Energia de Reserva';
            case 'cceeFees':
                    return 'Informativo da Taxa CCEE';
            default:
                return 'Outro';
        }
    }

    const handleNextPage = () => {
        if (currentPage < Math.ceil(logs.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentLogs = logs.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else !ready && getLogs()
    }, [ready])

    return <Admin pageTitle="Gerenciamento de Emails - Clientes Energizou" title="Gerenciamento" subtitle="Controle de Envio de Emails" classMain='adm_mailer'>
        {
            ready ? <>
                <div className="header">
                    <div className="buttons">
                        <button
                            type="button"
                            className="reportMailer"
                            onClick={() => openModal('reportMailer')}
                            data-for="tooltip_mailer_page"
                            data-tip="Relatório"
                        >
                            <FaEnvelopeOpenText size="16" />
                            <span>Relatórios</span>
                        </button>

                        <button
                            type="button"
                            className="salesOffMailer"
                            onClick={() => openModal('salesOffMailer')}
                            data-for="tooltip_mailer_page"
                            data-tip="Garantia Financeira"
                        >
                            <FaEnvelopeOpenText size="16" />
                            <span>Garantia</span>
                        </button>

                        <button
                            type="button"
                            className="bkpEnergyMailer"
                            onClick={() => openModal('bkpEnergyMailer')}
                            data-for="tooltip_mailer_page"
                            data-tip="Energia de Reserva"
                        >
                            <FaEnvelopeOpenText size="16" />
                            <span>Reserva</span>
                        </button>

                        <button
                            type="button"
                            className="cceeFeesMailer"
                            onClick={() => openModal('cceeFeesMailer')}
                            data-for="tooltip_mailer_page"
                            data-tip="Taxa CCEE"
                        >
                            <FaEnvelopeOpenText size="16" />
                            <span>Taxa CCEE</span>
                        </button>
                    </div>

                    <div className='searchBox'>
                        <input
                            name="search" id="search"
                            type="text" autoComplete='false'
                            placeholder='Tipo de envio e data referência...'
                            value={search} onChange={onChangeSearch}
                            disabled={ready ? false : true}
                        />

                        <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                            if (search !=='') {
                                setLogs(logsList)
                                setSearch('')
                            }
                        }}>
                            { search !=='' ? <FaTimes /> : <FaSearch /> }
                        </div>
                    </div>
                </div>

                <div className="logs">
                    {
                        currentLogs.length ? currentLogs.map((el: any) => <LogBox
                            key={`log_${el.hash}`}
                            {...el}
                        />) : <span className="alert">Não há logs registrados no momento</span>
                    }
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}><MdOutlineNavigateBefore /></button>
                        <span>Página {currentPage} de {Math.ceil(logs.length / itemsPerPage)}</span>
                        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(logs.length / itemsPerPage)}><MdOutlineNavigateNext /></button>
                    </div>
                </div>

                <ReactTooltip
                    id="tooltip_mailer_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminMailer;