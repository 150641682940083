import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import './styles.css';

interface Props {
    hash: string;
    type: string;
    typeName: string;
    dateRef: string;
    date: string;
    user: string;
    data: any;
}

const Logs: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen]   = useState<boolean>(false);

    return (
        <>
            <div className='log_line'>
                <div className="header">
                    <div className="hash">
                        <label>Hash</label>
                        <span>{props.hash.substring(0, 8)}</span>
                    </div>

                    <div className="type">
                        <label>Tipo</label>
                        <span>{props.typeName}</span>
                    </div>

                    <div className="dates">
                        <label>Referência</label>
                        <span>{props.dateRef}</span>
                    </div>

                    <div className="dates">
                        <label>Data do Envio</label>
                        <span>{props.date}</span>
                    </div>

                    <div className="user">
                        <label>Usuário</label>
                        <span>{props.user}</span>
                    </div>

                    <button className="open" onClick={() => setIsOpen(!isOpen)}>
                        { !isOpen ? <FaPlus /> : <FaMinus /> }
                    </button>
                </div>

                <div className={`data ${isOpen ? '' : 'none'}`}>
                    {
                        props.data && props.data.map((el: any) => (
                            <div className="data_line" key={`data_line_${el.id}`}>
                                <div
                                    className={`status ${el.status ? 'ok' : 'err'}`}
                                    data-for="tooltip_status"
                                    data-tip={el.message}
                                    data-type={el.status ? 'success' : 'error'}
                                ></div>
                                <div className="name">{el.name}</div>
                                <div className="mail">{el.mail}</div>
                                <div className="unit">{el.unit}</div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <ReactTooltip
                id="tooltip_status"
                place="right"
                effect="solid"
                type="info"
            />
        </>
    )
}

export default Logs;