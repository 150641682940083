import React from 'react';

// Contexts Providers
import { UsersProvider } from './users';
import { GroupsProvider } from './groups';
import { UnitsProvider } from './units';

const FormsProvider: React.FC<any> = ({children}) => {
    return (
        <UsersProvider>
            <GroupsProvider>
                <UnitsProvider>
                    { children }
                </UnitsProvider>
            </GroupsProvider>
        </UsersProvider>
    )
}

export {
    FormsProvider
};