import React from 'react';
import numbers from '~/utils/numbers';
import treatNotes from './treatNotes';

import './style.css';

interface Props {
    data: any;
    calc: any;
}

const CativeBox: React.FC<Props> = (props) => {
    const data = props.data;
    const calc = props.calc;

    // Init treat notes
    treatNotes.init(data, calc);

    return (
        <div className="cative">
            <h2>Custo do Mercado Cativo</h2>

            <div className="body">
                <div className="line title">
                    <div className="label"></div>
                    <div className="qtty">Quant.</div>
                    <div className="mult">Tarifas</div>
                    <div className="value">Valor</div>
                </div>

                {
                    data.modality==='Verde' ? (
                        <>
                            <div className="line">
                                <div className="label">Demanda Única (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.offPeak) }</div>
                                <div className="mult">{ numbers.format(calc.demandUniqueMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandUnique, false) }</div>
                            </div>
                            { data.demands.notConsumed.offPeak!==0 &&
                                <div className="line">
                                    <div className="label">Demanda Única Sem ICMS (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.notConsumed.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandUniqueMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandNoIcmsUnique, false) }</div>
                                </div>
                            }

                            { data.demands.overtaking.offPeak!==0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de demanda única (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingUniqueMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingUnique, false) }</div>
                                </div>
                            }
                        </>
                    ) : (
                        <>
                            <div className="line">
                                <div className="label">Demanda Ponta (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.peak) }</div>
                                <div className="mult">{ numbers.format(calc.demandPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandPeak, false) }</div>
                            </div>

                            <div className="line">
                                <div className="label">Demanda Fora Ponta (KW)</div>
                                <div className="qtty">{ numbers.format(data.demands.consumed.offPeak) }</div>
                                <div className="mult">{ numbers.format(calc.demandOffPeakMult, true, 5) }</div>
                                <div className="value">{ numbers.money(calc.demandOffPeak, false) }</div>
                            </div>

                            { calc.demandPeakNoIcms !== 0 &&
                                <div className="line">
                                    <div className="label">Demanda Ponta Sem ICMS (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.notConsumed.peak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandPeakNoICMS, false) }</div>
                                </div>
                            }

                            { calc.demandOffPeakNoIcms !== 0 &&
                                <div className="line">
                                    <div className="label">Demanda Fora Ponta Sem ICMS (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.notConsumed.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOffPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOffPeakNoICMS, false) }</div>
                                </div>
                            }

                            { data.demands.overtaking.peak !== 0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de Demanda Ponta (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.peak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingPeak, false) }</div>
                                </div>
                            }

                            { data.demands.overtaking.offPeak !== 0 &&
                                <div className="line">
                                    <div className="label">Ultrapassagem de Demanda Fora Ponta (KW)</div>
                                    <div className="qtty">{ numbers.format(data.demands.overtaking.offPeak) }</div>
                                    <div className="mult">{ numbers.format(calc.demandOvertakingOffPeakMult, true, 5) }</div>
                                    <div className="value">{ numbers.money(calc.demandOvertakingOffPeak, false) }</div>
                                </div>
                            }
                        </>
                    )
                }

                <div className="line">
                    <div className="label">TUSD Encargos Ponta (kWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionPeak) }</div>
                    <div className="mult">{ numbers.format(calc.consumptionPeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.consumptionPeak, false) }</div>
                </div>

                <div className="line">
                    <div className="label">TUSD Encargos Fora Ponta (kWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionOffPeak) }</div>
                    <div className="mult">{ numbers.format(calc.consumptionOffPeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.consumptionOffPeak, false) }</div>
                </div>

                <div className="line">
                    <div className="label">TE Ponta (kWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionPeakTE) }</div>
                    <div className="mult">{ numbers.format(calc.tePeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.tePeak, false) }</div>
                </div>

                <div className="line">
                    <div className="label">TE Fora Ponta (kWh)</div>
                    <div className="qtty">{ numbers.format(data.consumptionOffPeakTE) }</div>
                    <div className="mult">{ numbers.format(calc.teOffPeakMult, true, 5) }</div>
                    <div className="value">{ numbers.money(calc.teOffPeak, false) }</div>
                </div>

                <div className="line">
                    <div className="label">Base de Cálculo ICMS (R$)</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.calcIcms, false) }</div>
                </div>

                <div className="line">
                    <div className="label">Base de Cálculo PIS/COFINS (R$)</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.calcPisCofins, false) }</div>
                </div>

                <div className="line">
                    <div className="label">PIS/COFINS</div>
                    <div className="qtty"></div>
                    <div className="mult">{ numbers.format(data.pisCofins, true, 2) }%</div>
                    <div className="value">{ numbers.money(calc.pisCofins, false) }</div>
                </div>

                <div className="line">
                    <div className="label">ICMS</div>
                    <div className="qtty"></div>
                    <div className="mult">{ numbers.format(data.icms, true, 2) }%</div>
                    <div className="value">{ numbers.money(calc.icms, false) }</div>
                </div>

                {
                    calc.feeInterestCorrection !== 0 &&
                    <div className="line">
                        <div className="label">Multa, Juros e Correção (R$)</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value">{ numbers.money(calc.feeInterestCorrection, false) }</div>
                    </div>
                }

                {
                    calc.discounts !== 0 &&
                    <div className="line">
                        <div className="label">Descontos (R$)</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value"> -{ numbers.money(calc.discounts, false) }</div>
                    </div>
                }

                { data.others.cative !== 0 &&
                    <div className="line">
                        <div className="label">Outros Valores (R$)</div>
                        <div className="qtty"></div>
                        <div className="mult"></div>
                        <div className="value">{ numbers.money(data.others.cative, false) }</div>
                    </div>
                }

                <div className="line total">
                    <div className="label">Total</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.total, false) }</div>
                </div>

                <div className="line flag">
                    <div className="label">Bandeira Tarifária ({ calc.flagInfos.name }) - Acréscimo (R$/KWh): { numbers.money(calc.flagInfos.value, false) }</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.flag, false) }</div>
                </div>

                <div className="line total end">
                    <div className="label">Total com Bandeira</div>
                    <div className="qtty"></div>
                    <div className="mult"></div>
                    <div className="value">{ numbers.money(calc.totalFlag, false) }</div>
                </div>
            </div>
        </div>
    );
}

export default CativeBox;