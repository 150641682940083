import React, { ChangeEventHandler } from 'react';
import './styles.css';

interface Props {
    id: string;
    name: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    checked?: number;
    labelName?: string;
    labelClick?: string;
    modal?:any;
    disabled?: boolean;
    func?:boolean;
    corr?: any;
}

const Checkbox: React.FC<Props> = (props) => (
    <div className={`checkbox ${props.corr ? 'err' : ''}`}>
        <input
            id={props.id}
            name={props.name}
            type="checkbox"
            defaultChecked={props.checked && props.checked===1 ? true : false}
            onChange={props.onChange ? props.onChange : undefined}
            disabled={props.disabled ? props.disabled : false}
            value={props.checked ? '1' : '0'}
        />

        {
            props.labelName &&
                <label htmlFor={props.id}>
                    {props.labelName}  <b style={{textDecoration:'underline'}} onClick={() => props.modal()}>{props.labelClick}</b>
                </label>
        }
    </div>
);

export default Checkbox;


