import React, {ChangeEventHandler, CSSProperties} from 'react';
import './styles.css';
import InputMask from 'react-input-mask';
import { FiCheckCircle, FiRotateCw, FiXCircle } from 'react-icons/fi';


interface Props {
    name          : string;
    labelName    ?: string;
    style        ?: CSSProperties;
    type          : string;
    value        ?: any;
    dataType     ?: string;
    onChange     ?: ChangeEventHandler<HTMLInputElement>;
    placeholder  ?: string;
    autoComplete ?: boolean;
    maxLenght    ?: number;
    minLenght    ?: number;
    disabled     ?: boolean;
    mask         ?: string ;
    error        ?: any;
    cnpj         ?: any;
}

 const InputLabel:React.FC<Props> = (props: Props) => (
    <div className={`inputLabel${props.error ? ' hasError' : ''}`} style={props.style ? props.style : {}}>
        {props.labelName ? <label htmlFor={props.name}>{props.labelName}</label> : <></>}

        <input
            id={props.name}
            type={props.type}
            name={props.name}
            value={props.value!==null ? props.value : ''}
            data-type={props.dataType ? props.dataType : ''}
            onChange={props.onChange ? props.onChange : undefined}
            placeholder={props.placeholder}
            autoComplete={!props.autoComplete ? 'new-password' : ''}
            maxLength={props.maxLenght}
            minLength={props.minLenght}
            disabled={props.disabled ? true : false}
        />

        {props.error ? <small>{props.error}</small> : <></>}
    </div>
);

export const InputLabelMask:React.FC<Props> = (props: Props) => (
    <div className={`mask inputLabel${props.error ? ' hasError' : ''}`} style={props.style ? props.style : {}}>
        {props.labelName ? <label htmlFor={props.name}>{props.labelName}</label> : <></>}

        <InputMask
            className={props.error ? ' hasError' : ''}
            id={props.name}
            mask={props.mask+''}
            type={props.type}
            name={props.name}
            value={props.value ? props.value : ''}
            data-type={props.dataType ? props.dataType : ''}
            onChange={props.onChange ? props.onChange : undefined}
            placeholder={props.placeholder}
            maxLength={props.maxLenght}
            minLength={props.minLenght}
            disabled={props.disabled ? true : false}
        />
        {
            props.cnpj ?
                props.cnpj===0 ? <></> : (
                    props.cnpj<0 ? <FiRotateCw className="loading" size={26} /> : (
                        props.cnpj===2 ?
                        <FiXCircle className="error" size={26} /> :
                        <FiCheckCircle className="success" size={26} />
                    )
                )
            : <></>
        }

        {props.error ? <small>{props.error}</small> : <></>}
    </div>
);


export default InputLabel;