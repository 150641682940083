import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';
import { UsersContext } from '~/contexts/forms/users';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import UsersBox from '~/components/Boxs/AdminUsers';
import FormUsers from './Form';

const AdminUsers: React.FC<any> = () => {
    const navigate          = useNavigate();
    const [ready, setReady] = useState<Boolean>();
    const [users, setUsers] = useState<any>([]);

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);
    const Users  = useContext(UsersContext);

    function openModal (modal: string, data?: any) {
        let newUpdate     = false;
        let dataUser: any = null;

        Modal.setModalOpen(false);
        Modal.setModalClass('musers');

        switch (modal) {
            case 'addUser':
                Modal.setModalTitle('Adicionando Usuário')

                dataUser = {
                    id       : 0,
                    type     : 'user',
                    image    : '',
                    name     : '',
                    mail     : '',
                    active   : 1,
                    password : ''
                }

                newUpdate = true;
                break;

            case 'editUser':
                Modal.setModalTitle('Editando Usuário')

                if (data) {
                    dataUser = {
                        id      : data.id,
                        type    : data.type,
                        image   : data.image_url,
                        name    : data.name,
                        mail    : data.mail,
                        active  : data.active,
                        password: ''
                    }
                }

                newUpdate = true;
                break;

            case 'deleteUser':
                Modal.setModalClass('delete')
                Modal.setModalTitle('Excluindo Usuário')
                Modal.setModalBody(<>
                    <div>Deseja realmente excluir o usuário <i>"{ data?.name }"?</i></div>
                    <div className="alert">* Tenha cuidado, esse processo é irreversível!</div>

                    <footer>
                        <button id="btnDelete" type="button" onClick={() => Users.remove(data.id, Modal, setReady)}>Excluir</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)
                Modal.setModalOpen(true)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        if (newUpdate) {
            Modal.setModalBody(<FormUsers user={dataUser} setReady={setReady} />)
            Modal.setModalOpen(true)
        }
    }

    useEffect(() => {
        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else api.get('users', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setUsers(resp.data);
            setReady(true);
        });
    }, [user, Modal.modalOpen]);

    return <Admin pageTitle="Gerenciamento de Usuários - Clientes Energizou" title="Gerenciamento" subtitle="Usuários Energizou" classMain='adm_users'>
        {
            ready ? <>
                <div className="buttons">
                    <button
                        type="button"
                        className="addUser"
                        onClick={() => openModal('addUser')}
                        data-for="tooltip_users_page"
                        data-tip="Adicionar Usuário"
                    >
                        <FiPlus size="16" />
                    </button>
                </div>

                <div className={`users ${!ready ? 'loading' : ''}`}>
                    {
                        users.map((userData: any) => {
                            return (
                                <UsersBox
                                    key={'user_' + userData.id}
                                    {...userData}
                                    token={user.token}
                                    edit={() => openModal('editUser', userData)}
                                    delete={() => openModal('deleteUser', userData)}
                                />
                            )
                        })
                    }
                </div>

                <ReactTooltip
                    id="tooltip_users_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminUsers;