import React, { useEffect, useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaPen, FaSignOutAlt } from 'react-icons/fa';
import ReactTooltip from "react-tooltip";
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import FormProfile from './Form';

// Images
import userImg from '~/assets/images/user.jpg';

const UserBox: React.FC = () => {
    const [change, setChange] = useState<Boolean>(false);
    const navigate            = useNavigate();

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function openModal(type: string) {
        Modal.setModalOpen(false);

        switch (type) {
            case 'User':
                let profileData = { id: user.id, image: user.image, image_url: user.image_url, name: user.name, mail: user.mail };
                Modal.setModalClass('mprofile');
                Modal.setModalTitle('Editando Perfil');
                Modal.setModalBody(<FormProfile profile={profileData} setChange={setChange} />);
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        Modal.setModalOpen(true)
    }

    useEffect(() => {
        if (change) {
            setChange(false);
            navigate('/logout', {replace: true})
        }
    }, [change]);

    return (
        <>
            <div className="user" data-tip="React-tooltip" data-for="userBox">
                <img src={user.image_url!==null ? user.image_url : userImg} alt={`Foto do Usuário ${user.name}`} />

                <ReactTooltip id="userBox" place="bottom" type="light" effect="solid" clickable={true}>
                    <div className="body">
                        <div className="name">{user.name}</div>
                        <small>{user.mail}</small>
                    </div>

                    <div className="footer">
                        <button className="editUser" onClick={() => openModal('User')}>
                            <FaPen size={13} />
                        </button>

                        <Link to='/logout' className="logout" title="Realizar Logout">
                            <FaSignOutAlt size={18} />
                        </Link>
                    </div>
                </ReactTooltip>
            </div>
        </>
    );
}

export default UserBox;