import React from 'react';
import { FiEdit2 } from 'react-icons/fi';
import UsersPermissionInt from '~/interfaces/UsersPermission';
import './styles.css';

// Images
import user_image from '~/assets/images/user.jpg';

interface CompProps {
    edit: Function;
    token: string;
}

type Props = UsersPermissionInt & CompProps;

const UsersPermission: React.FC<Props> = (props) => {
    function getUserType (type: string) {
        switch (type) {
            case 'admin':
                return 'Administrador';

            case 'client':
                return 'Cliente';

            default:
                return 'Usuário';
        }
    }

    return (
        <div className="userPermissionBox">
            <div className="userPermissionData">
                <img src={props.image_url ? props.image_url : user_image} alt={`Imagem do usuário ${props.name}`} />

                <div className="infos">
                    <h3>{ props.name }</h3>
                    <div className="type">{ getUserType(props.type) }</div>
                </div>
            </div>

            <div className="buttons">
                <button type="button" className="editUser" onClick={() => props.edit ? props.edit() : ''} title="Editar Permissão">
                    <FiEdit2 size={20} /><span> Editar Permissão</span>
                </button>
            </div>
        </div>
    );
}

export default UsersPermission;