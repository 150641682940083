const numbers = {
    format: function (num: number, dec?: boolean, decQtty?: number) {
        let form: any = '';

        if (dec && dec===true) {
            switch (decQtty) {
                case 1:
                    form = (Math.round(num*10)/10).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                case 2:
                    form = (Math.round(num*100)/100).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                case 3:
                    form = (Math.round(num*1000)/1000).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                case 4:
                    form = (Math.round(num*10000)/10000).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                case 5:
                    form = (Math.round(num*100000)/100000).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                case 6:
                    form = (Math.round(num*1000000)/1000000).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], decQtty);
                    return form.replace('.', ',').replace('_', '.');

                default:
                    form = (Math.round(num*100)/100).toString().split('.');
                    form = form[0].replace(/\B(?=(\d{3})+(?!\d))/g, "_") + '.' + this.addZero(form[1], 2);
                    return form.replace('.', ',').replace('_', '.');
            }
        }

        return Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    money: function (curr: number, prefix?: boolean, digits?: boolean, digitsQtty?: number) {
        if (!curr) return (prefix===true ? 'R$ ' : '') + '0' + (digits===false ? '' : ',' + this._treatDigits(digitsQtty ? digitsQtty : 2));
        let config: any = {style: 'currency', currency: 'BRL'};

        if (prefix===false) {
            config = digits===false ? {style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0} : {style: 'currency', currency: 'BRL', minimumFractionDigits: (digitsQtty ? digitsQtty : 2)};
            return curr.toLocaleString('pt-br', config).replace('R$', '').trim();
        } else {
            config = digits===false ? {style: 'currency', currency: 'BRL', minimumFractionDigits: 0, maximumFractionDigits: 0} : {style: 'currency', currency: 'BRL', minimumFractionDigits: (digitsQtty ? digitsQtty : 2)};
            return curr.toLocaleString('pt-br', config);
        }
    },

    _treatDigits: function (qtty: number) {
        let ret = '';

        for (let i=0; i<qtty; i++) {
            ret += '0'
        }

        return ret;
    },

    upRound: function (num: number) {
        let leng  = String(num).replace(/\s+/g, '').length,
            value = 0,
            base  = 0,
            ret: Array<Number> = [0];

        switch (leng) {
            case 1:
                value = (num<=5 ? (num===1 ? num : num+1) : 10);
                break;

            case 2:
                value = Math.ceil(num/10)*10;
                break;

            case 3:
                value = Math.ceil(num/100)*100;
                break;

            default:
                value = Math.ceil(num/1000)*1000;
                break;
        }

        base = value/2;
        ret.push(base);
        ret.push(base*2);

        return ret;
    },

    addZero: function (dec: string, qtty: number) {
        dec = (dec ? dec : '');
        let diff = (qtty-dec.length);

        if (diff>0) {
            for (let i=0; i<diff; i++) {
                dec += '0';
            }
        }

        return dec;
    }
}

export default numbers;