import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Import Error
import { Error404 } from '~/pages';

// Import Auths
import Login from '~/pages/Auth/Login';
import Logout from '~/pages/Auth/Logout';

// Import Pages
import Dashboard from '~/pages/Dashboard';
import Report from '~/pages/Report';
import Bills from '~/pages/Bills';
import Documents from '~/pages/Documents';

// Import Admin Pages
import Groups from '~/pages/Administrator/Groups';
import Clients from '~/pages/Administrator/Units';
import Permissions from '~/pages/Administrator/Permissions';
import MailerCreate from '~/pages/MailerCreate';
import MailerLogs from '~/pages/Administrator/MailerLogs';
import Users from '~/pages/Administrator/Users';
import AdminHelp from '~/pages/Administrator/ErrorsUpload';
import Forgot from '~/pages/Auth/Forgot';
import NewPassword from '~/pages/Auth/NewPassword';
import ReadingHistory from '~/pages/Administrator/ReadingHistory';

const SystemRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                {/* Auth */}
                <Route path="/login" element={<Login />}>
                    <Route path=":text" element={<Login />} />
                </Route>
                <Route path="/logout" element={<Logout />} />
                <Route path="/lembrar-senha" element={<Forgot />} />
                <Route path="/nova-senha" element={<NewPassword />}>
                    <Route path=":text" element={<NewPassword />} />
                </Route>

                {/* User */}
                <Route path="/" element={<Dashboard />}>
                    <Route path="/ref/:yearMonth" element={<Dashboard />} />
                </Route>
                <Route path="/relatorio" element={<Report />}>
                    <Route path=":yearMonth" element={<Report />} />
                </Route>
                <Route path="/contas" element={<Bills />} />
                <Route path="/documentos" element={<Documents />} />
                <Route path="/emails/cadastro" element={<MailerCreate />} />

                {/* Admin */}
                <Route path="/admin/grupos" element={<Groups />} />
                <Route path="/admin/clientes" element={<Clients />} />
                <Route path="/admin/permissoes" element={<Permissions />} />
                <Route path="/admin/emails/cadastro" element={<MailerCreate />} />
                <Route path="/admin/emails/logs" element={<MailerLogs />} />
                <Route path="/admin/usuarios" element={<Users />} />
                <Route path="/admin/s/helpAdmin" element={<AdminHelp />} />
                <Route path="/admin/s/historicoDeLeitura" element={<ReadingHistory />} />

                {/* Site */}
                <Route path="/404" element={<Error404 />} />

                {/* Error */}
                <Route path="/*" element={<Navigate to="/404" replace />} />
            </Routes>
        </BrowserRouter>
    )
}

export default SystemRoutes;