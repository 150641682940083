import React from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.css';

interface Props {
    status: boolean;
    unit: string;
    mail: string;
    message: string;
}

const Status: React.FC<Props> = (props) => {
    return (
        <>
            <div className='status_line'>
                <span
                    className={`status ${props.status ? '' : 'err'}`}
                    data-for="tooltip_box_status"
                    data-tip={props.message}
                    data-type={props.status ? 'success' : 'error'}
                ></span>
                <span className='unit'>{props.unit}</span>
                <span className='mail'>{props.mail}</span>
            </div>

            <ReactTooltip
                id="tooltip_box_status"
                place="right"
                effect="solid"
                type="info"
            />
        </>
    )
}

export default Status;