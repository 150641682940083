import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '~/services/api';
import sort_by from '~/utils/sort';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import UsersPermission from '~/components/Boxs/AdminPermission';
import FormUserPermissions from './Form';

const AdminPermissions: React.FC<any> = () => {
    const navigate            = useNavigate();
    const [ready, setReady]   = useState<Boolean>();
    const [users, setUsers]   = useState<any>([]);
    const [groups, setGroups] = useState<[]>();
    const [units, setUnits]   = useState<[]>();

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function openModal(modal: any, data?: any) {
        Modal.setModalOpen(false);
        Modal.setModalClass('mpermissions');

        switch (modal) {
            case 'editPermission':
                if (data) {
                    Modal.setModalTitle('Editando Permissões');

                    let dataPermission = null;

                    if (data.length && data.length>0) {
                        dataPermission = [];

                        data.map((el: any) => {
                            dataPermission.push({
                                user_id  : el.user_id ? parseInt(el.user_id) : 0,
                                client_id: el.client_id ? parseInt(el.client_id) : 0,
                                unit_id  : el.unit_id ? parseInt(el.unit_id) : 0,
                            });
                        });
                    } else {
                        dataPermission = {
                            user_id  : data.user_id ? parseInt(data.user_id) : 0,
                            client_id: data.client_id ? parseInt(data.client_id) : 0,
                            unit_id  : data.unit_id ? parseInt(data.unit_id) : 0,
                        }
                    }

                    Modal.setModalBody(<FormUserPermissions permission={dataPermission} groups={groups || []} units={units || []} setReady={setReady} />);
                    Modal.setModalOpen(true)
                }
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }
    }

    useEffect(() => {
        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else !ready && api.get('users/permissions', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setUsers(resp.data.users.sort(sort_by({ name: 'type' }, { name: 'name' })));
            setGroups(resp.data.groups);
            setUnits(resp.data.units);
            setReady(true);
        });
    }, [user, ready]);

    return <Admin pageTitle="Gerenciamento de Permissões - Clientes Energizou" title="Gerenciamento" subtitle="Permissões dos Clientes" classMain='adm_permissions'>
        {
            ready ? <div className="users">
                {
                    users.map((user: any, index: number) => <UsersPermission
                        key={`key_box_${index}`}
                        {...user}
                        edit={() => openModal('editPermission', user.permission)}
                    />)
                }
            </div> : <LoadingSingle />
        }
    </Admin>
}

export default AdminPermissions;