import numbers from "~/utils/numbers";
import { useEffect, useState } from "react";
import Numbers from '~/utils/numbers'
import "./styles.css";

//Images
import gold from '~/assets/images/budgets/gold.png';
import silver from '~/assets/images/budgets/silver.png';
import bronze from '~/assets/images/budgets/bronze.png';

interface Props {
    economy: any;
    reference: any;
}

const EconomyBox: React.FC<Props> = (props) => {
	const [budget, setBudget] = useState<any>(null);

	function calculateNext () {
		let next 	  = 0;
		let silverRef = props.reference*5;
		let goldRef   = props.reference*9;

		switch (budget.type) {
			case 'bronze':
				next = silverRef-props.economy;
				break;

			case 'silver':
				next = goldRef-props.economy;
				break;

			default:
				break;
		}

		if (budget.type==='gold') return 'Parabéns você alcançou o nível Ouro!!!';
		else if (budget.type!=='gold' && next>0) return `${Numbers.money(next)} de economia requeridos para o próximo nível`;
		return ''
	}

	useEffect(() => {
		if(!budget){
			let reference = props.reference && props.reference>0 ? props.reference : null;
			let bronzeRef = props.reference*2;
			let silverRef = props.reference*5;
			let goldRef   = props.reference*9;

			if (reference && props.economy>bronzeRef && props.economy<silverRef) {
				setBudget({name: 'Bronze', type: 'bronze', icon: bronze});
			} else if (reference && props.economy>silverRef && props.economy<goldRef) {
				setBudget({name: 'Prata', type: 'silver', icon: silver});
			} else if (reference && props.economy>goldRef) {
				setBudget({name: 'Ouro', type: 'gold', icon: gold});
			}
		}
	}, [props])

  	return <div className="economyBox">
		{
			budget ? (
				<>
					<div className="header">
						<div>Sua economia</div>
						<div className="eco">R$ {numbers.money(props.economy, false)}</div>
						<div>Economizados</div>
					</div>

					<div className="budget">
						<img src={budget.icon} alt='nível'/>
						<p>Você está no nível <b>{ budget.name }</b></p>
					</div>

					<div className="warning">{ calculateNext() }</div>
				</>
			) : <></>
		}
	</div>
}

export default EconomyBox;