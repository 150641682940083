import React from 'react';
import loaging from './images/loading-simple.svg';
import './styles.css';

const LoadingSimple: React.FC = () => {
    return (
        <div className="loading-simple">
            <img src={loaging} alt="Loading..." />
        </div>
    )
}

export default LoadingSimple;