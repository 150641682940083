import React, { ReactNode } from 'react';
import { createContext } from 'react';

interface ThemeContextData {
    theme: string;
    setTheme: Function;
}

interface ThemeProviderProps {
    initialTheme: string;
    children: ReactNode;
}

// const getInitialTheme = () => {
//     if (typeof window !== 'undefined' && window.localStorage) {
//         const storedPrefs = window.localStorage.getItem('color-theme');
//         if (typeof storedPrefs === 'string') return storedPrefs;

//         const userMedia = window.matchMedia('(prefers-color-scheme: dark)');
//         if (userMedia.matches) return 'dark';
//     }

//    return 'light' // light theme as the default;
// };

export const ThemeContext = createContext({} as ThemeContextData)

export function ThemeProvider ({initialTheme, children}: ThemeProviderProps) {
    const [theme, setTheme] = React.useState('light');
    // const [theme, setTheme] = React.useState(getInitialTheme);

    const rawSetTheme = (rawTheme: string) => {
        const root = window.document.documentElement;
        const isDark = rawTheme === 'dark';

        root.classList.remove(isDark ? 'light' : 'dark');
        root.classList.add(rawTheme);
        localStorage.setItem('color-theme', rawTheme);
    };

    if (initialTheme) rawSetTheme(initialTheme);

    React.useEffect(() => {
        // rawSetTheme(theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};