import React, { useState, useEffect, useContext } from 'react';
import api from '~/services/api';
import DocsInt from '~/interfaces/Docs';
import './styles.css';

// Contexts
import { GeneralContext } from '~/contexts';

// Import Components
import AdminDesign from '~/components/Admin';
import DocBox from '~/components/Boxs/Documents';
import Loading from '~/components/Default/Loading';
import cryptos from '~/utils/cryptos';

const Documents: React.FC = () => {
    const { user, unit }            = useContext(GeneralContext);
    const unitFolder                = cryptos.encryptServer(unit.id + '-' + unit.folder);
    const urlInit                   = 'documents/user/' + unitFolder;
    const [ready, setReady]         = useState<Boolean>();
    const [url, setUrl]             = useState<string>(urlInit);
    const [subtitle, setSubtitle]   = useState<string>('Meus Documentos');
    const [documents, setDocuments] = useState<DocsInt[]>([]);

    function changeDocuments(type?: string) {
        setReady(false);
        let urlDoc = '';

        if (type && type==='user') {
            urlDoc = urlInit;
            setSubtitle('Meus Documentos');
        } else {
            urlDoc = 'documents';
            setSubtitle('Documentos Energizou');
        }

        setUrl(urlDoc);
    }

    useEffect(() => {
        api.get(url, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setDocuments(resp.data);
            setReady(true);
        });
    }, [user, url]);

    return (
        <AdminDesign pageTitle="Documentos - Clientes Energizou" title="Documentos" subtitle={subtitle} classMain="documents">
            <div className="buttons">
                <button className={'me' + (ready ? '' : ' noReady') + (url.indexOf('documents/user')>=0 ? ' active' : '')} onClick={() => (url.indexOf('documents/user')<0 ? changeDocuments('user') : '')}>
                    Meus Documentos
                </button>

                <button className={'en' + (ready ? '' : ' noReady') + (url==='documents' ? ' active' : '')} onClick={() => (url!=='documents' ? changeDocuments() : '')}>
                    Energizou
                </button>
            </div>

            <div className="docs">
                {
                    ready ?
                    (
                        documents.length>0 ?
                        documents.map(doc => <DocBox key={doc.id} {...doc} token={user.token} doc_ref={subtitle} />) :
                        <div className="alert">Nenhum documento localizado nesta pasta</div>
                    ) : <div className="alert"><Loading /></div>
                }
            </div>

        </AdminDesign>
    );
}

export default Documents;