import React, {useContext} from 'react';
import { GeneralContext } from '~/contexts';
import './styles.css';

interface Props {
    data   : any;
    change : Function;
}

const UnitBox: React.FC<Props> = (props) => {
    const data     = props.data;
    const { unit } = useContext(GeneralContext);

    return (
        <button onClick={() => props.change(data.id)} className={`unitBox${unit.id===data.id ? ' active' : ''}`}>
            <h4>{ data.name }</h4>
            <h5>{ data.city } / { data.state }</h5>
        </button>
    )
}

export default UnitBox;