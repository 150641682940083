import axios from 'axios';
import env from '~/utils/env';

let host = () => {
    if (env.stage!=='local') return env.host;
    return env.host.replace('#MY_IP#', window.location.hostname);
}

const api = axios.create({
    baseURL: host()
});

// api.defaults.headers = {
//     'Cache-Control': 'no-cache',
//     'Pragma': 'no-cache',
//     'Expires': '0',
// };

export default api;