import crypto from 'crypto';
import config from '~/utils/env';

const cryptos = {
    algorithm: 'aes-256-ctr',
    iv_length: 16,

    generateMD5: function (text: string) {
        return crypto.createHash('md5').update(text).digest("hex");
    },

    encryptWeb: function (text: string | object, obj?: boolean) {
        const textEnc = (!obj ? JSON.stringify(text) : text);
        const encrypt = this._encrypt(textEnc, config.crypto.web);
        return encrypt;
    },

    decryptWeb: function (text: string | null, obj?: boolean) {
        const decrypt = this._decrypt(text, config.crypto.web);
        if (text==='' || (decrypt.charAt(0)!=='{' && decrypt.charAt(0)!=='[')) return '';
        return (!obj ? JSON.parse(decrypt) : decrypt);
    },

    encryptServer: function (text: string | object, obj?: boolean) {
        const textEnc = (!obj ? JSON.stringify(text) : text);
        const encrypt = this._encrypt(textEnc, config.crypto.server);
        return encrypt;
    },

    decryptServer: function (text: string | null, obj?: boolean) {
        const decrypt = this._decrypt(text, config.crypto.server);
        if (text==='') return '';
        return (!obj ? JSON.parse(decrypt) : decrypt);
    },

    _encrypt: function (text: any, password: string) {
        const iv           = crypto.randomBytes(this.iv_length);
        const cipher       = crypto.createCipheriv(this.algorithm, Buffer.from(password).slice(0, 32), iv);
        let crypted: any   = cipher.update(text);
        crypted            = Buffer.concat([crypted, cipher.final()]);

        return iv.toString('hex') + ':' + crypted.toString('hex');
    },

    _decrypt: function (text: any, password: string) {
        const parts     = text.split(':');
        const iv        = Buffer.from(parts.shift(), 'hex');
        const encrypted = Buffer.from(parts.join(':'), 'hex');
        const decipher  = crypto.createDecipheriv(this.algorithm, Buffer.from(password).slice(0, 32), iv);
        let   decrypted = decipher.update(encrypted);
            decrypted   = Buffer.concat([decrypted, decipher.final()]);

        return decrypted.toString();
    }
}

export default cryptos;