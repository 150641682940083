const SYMBOL_LENGTH = 3;
const formatCurrency = (locale: string | undefined = 'pt-BR', value: number, currencyType: string = 'BRL', hideSymbol: boolean = false, digits?: number): string => {
    let minMaxDigits = digits ? digits : 2;
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyType,
        currencyDisplay: 'symbol',
        minimumFractionDigits: minMaxDigits,
        maximumFractionDigits: minMaxDigits,
    });

    const formattedValue = formatter.format(value);
    return formattedValue.slice(hideSymbol ? SYMBOL_LENGTH : 0);
};

export default formatCurrency;