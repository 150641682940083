import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { FiEdit2, FiSave, FiTrash2, FiX } from 'react-icons/fi';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import funcs from '~/utils/funcs';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts';
import { UnitsContext } from '~/contexts/forms/units';
import { FaSave } from 'react-icons/fa';

const Form: React.FC<any> = ({unit, energies, setReady}) => {
    const [energiesData, setEnergiesData] = useState<any>(null);
    const [energyV, setEnergyV]           = useState<any>();
    const [energyPO, setEnergyPO]         = useState<any>();

    // Contexts
    const Units = useContext(UnitsContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let newData = JSON.parse(JSON.stringify(energyV))
        let data    = funcs.onChange(newData, name, value);
        setEnergyV(data);
    }

    function getMonths () {
        let ret: any = [];

        for (let i=0; i<=11; i++) {
            let item = {
                month: i+1,
                name: Dates._getNameMonth(i)
            };

            ret.push(item);
        }

        return ret;
    }

    function getYears () {
        let init = Number(Dates.getYear()),
            ret: any = [];

        for (let i=init; i>=(init-20); i--) {
            ret.push(i);
        }

        return ret;
    }

    function getMonthName(month: number) {
        month = Number(month);
        if (month===0) return 'Geral';
        return Dates._getNameMonth((month-1));
    }

    function addEnergy(index: number) {
        let date = new Date()
        let data  = {unit_id: unit, year: date.getFullYear(), month: 0, type: 'conv', index};
        let datas = JSON.parse(JSON.stringify(energiesData));

        // Add Item
        datas.push(data);
        //datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setEnergiesData(datas);
    }

    function editEnergy(elem: any, index: number) {
        let item = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.add('active');

        setEnergyV(elem);
        setEnergyPO(elem);
    }

    function deleteEnergy(elem: any) {
        let datas = JSON.parse(JSON.stringify(energiesData));
        let idx = datas.findIndex((q: any) => q.name===elem.name && q.mail===elem.mail && q.unit_id===elem.unit_id);

        // Remove Item
        datas.splice(idx, 1);
        //datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setEnergiesData(datas);
    }

    function saveEnergy(elem: any, index: number) {
        let datas = JSON.parse(JSON.stringify(energiesData));
        let item  = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.remove('active');

        // Change Item
        let idx = datas.findIndex((q: any) => q.year===energyPO.year && q.month===energyPO.month && q.unit_id===energyPO.unit_id && q.index===index);
        datas.splice(idx, 1, energyV);
        //datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setEnergiesData(datas);

        // Clear Data Items
        setEnergyV(null);
        setEnergyPO(null);
    }

    function cancelEnergy(elem: any, index: number) {
        let item = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.remove('active');
        setEnergyV(null);
    }

    async function getData(){
        let ret: any = []
        let energiesDB = energies.length>0 ? energies.sort(sort_by({name: 'year', reverse: true}, {name: 'month'})) : []
        await energiesDB.map((el:any, index: number) => ret.push({...el, index}))
        setEnergiesData(ret)
    }

    useEffect(() => {

        if (!energiesData) getData()
    }, [energies, energiesData])

    return (
        energiesData && <>
            {
                energiesData.length>0 ? (
                    <div className="header">
                        <div className="infos">
                            <div className="year">Ano</div>
                            <div className="month">Mês</div>
                            <div className="type">Tipo</div>
                        </div>

                        <div className="buttons"></div>
                    </div>
                ) : <></>
            }

            {
                energiesData.map((el: any, index: number) => (
                    <div className="line" key={`${el.unit_id}_${el.year}_${el.month}_${energiesData.length - index - 1}`} data-rel={`${el.unit_id}_${el.year}_${el.month}_${index}`}>

                        <div className="infos">
                            <div className="item">
                                <div className="year">{ el.year }</div>
                                <div className="month">{ getMonthName(el.month) }</div>
                                <div className="type">{ el.type==='inc' ? 'Incentivada' : 'Convencional' }</div>
                            </div>

                            <div className="edit">
                                <div className="year">
                                    <select
                                        name="year" id="year" onChange={onChange}
                                        defaultValue={energyV?.year ? energyV?.year : el.year}
                                    >
                                        {
                                            getYears().map((ye: any) => (
                                                <option key={`key_${ye}`} value={ye}>{ye}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="month">
                                    <select
                                        name="month" id="month" onChange={onChange}
                                        defaultValue={el.month ? el.month : '0'}
                                    >
                                        <option value="0">Geral</option>

                                        <optgroup label="Meses">
                                            {
                                                getMonths().map((mo: any) => (
                                                    <option key={`month_${mo.name}`} value={mo.month}>{mo.name}</option>
                                                ))
                                            }
                                        </optgroup>
                                    </select>
                                </div>

                                <div className="type">
                                    <select
                                        id="type" name="type" onChange={onChange}
                                        defaultValue={el.type}
                                    >
                                        <option value="conv">Convencional</option>
                                        <option value="inc">Incentivada</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="buttons">
                            <div className="item">
                                <button className="edit" onClick={() => editEnergy(el,index)} disabled={(!energyV ? false : true)}>
                                    <FiEdit2 size={16} />
                                </button>

                                <button className="del" onClick={() => deleteEnergy(el)} disabled={(!energyV ? false : true)}>
                                    <FiTrash2 size={16} />
                                </button>
                            </div>

                            <div className="edit">
                                <button className="save" onClick={() => saveEnergy(el,index)}>
                                    <FiSave size={16} />
                                </button>

                                <button className="cancel" onClick={() => cancelEnergy(el,index)}>
                                    <FiX size={16} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }

            <div className="footerBottons">
                <button className="btn add" onClick={() => addEnergy(energiesData.length)}>Adicionar novo tipo de energia</button>
                <button className="btn save" onClick={() => Units.saveEnergies(unit, energiesData, Modal, setReady)}><FaSave /></button>
            </div>
        </>
    );
}

export default Form;