import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {FaTimes} from 'react-icons/fa';
import {  GeneralContext } from '~/contexts';
import cryptos from '~/utils/cryptos';
import api from '~/services/api';
import apiIP from '~/services/getIp'

import './styles.css';

// Components
import { Checkbox, InputLabel } from '~/components/Parts/Forms';

// Images
import logo from '~/assets/images/logo.svg';

// Constants
const initialState = {mail: '', password: '', ip: apiIP.get()};

const Login: React.FC = () => {
	let params = useParams();
	let text   = params.text;

    const navigate                = useNavigate();
    const {user, login}           = useContext(GeneralContext);
    const [values, setValues]     = useState(initialState);
    const [message, setMessage]   = useState<string>();
    const [errors, setErrors]     = useState<String[]>([]);
    const [reactive, setReactive] = useState<any>();

	function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        setValues({...values, [name]: value})
    }

	async function onSubmit (event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (values.mail==='' || values.password==='') {
			var errArr = [];

			if (values.mail==='') errArr.push('O campo de email está vazio');
			if (values.password==='') errArr.push('O campo de senha está vazio');

			setErrors(errArr);
			return;
		}

		let dataSubmit = {...values, ip: await apiIP.get()}

		api.post('login', dataSubmit).then(async (resp: any) => {
			const user = cryptos.decryptServer(resp.data);
			login(user);
			document.querySelector('#root')?.classList.remove('login');
			navigate('/', {replace: true})
		}).catch((err: any) => {
			const { status } = err.response;
			if (status===400) {
				const error = err.response.data.errors;
				setErrors(error);
			}

			if (status===403) {
				const id = err.response.data.id;
				const error = err.response.data.errors;
				setErrors(error);
				setReactive(id);
			}
		});

		setValues({ ...values, password: '' });
	}

	function reactivateUser(){
		return typeof reactive == 'number' ?
			<p> <small style={{
				textDecoration:'underline',
				fontSize:'inherit',
				cursor:'pointer'
			}} onClick={() =>reactivateUserApi()}>
				Clique aqui
			</small> para reenviar seu e-mail de ativação</p> :
			<p>{reactive}</p>
	}

	function reactivateUserApi(){
		api.get('reactivate/'+reactive).then(async (resp: any) => {
			resp.data.activeMail===true ?
				setReactive('E-mail enviado com sucesso') :
				setReactive('Ops, houve um erro! Recarregue a página e tente novamente')
		}).catch((err: any) => {
			setReactive(err.response.data)
		});
	}

	function treatErrors() {
        if (message!=='') {
            setMessage('');
        }

        return <div className="boxErrors">
			{ errors.map(el => <div key={`error_${el}`} dangerouslySetInnerHTML={{__html: String(el)}} />) }
			{ reactive ? reactivateUser() : ''}
		</div>
    }

    function treatMessage() {
        return (
            <div className="boxMessage">
                <div className="message" dangerouslySetInnerHTML={{__html: String(message)}} />

                <button type="button" onClick={() => { setMessage(''); navigate('/login', {replace: true}) }} className="close">
                    <FaTimes size="16" />
                </button>
            </div>
        )
    }

	// Set Message
	useEffect(() => {
        text && setMessage(Buffer.from(text, 'base64').toString('ascii'))
    }, [user, text, errors])

	return (
		<div id="login">
			<div className='header'>
				<img src={logo} alt="Logo Energizou" />

				<h2>Faça login para continuar</h2>
			</div>

			{ Array.isArray(errors) && errors.length>0 && treatErrors() }
			{ !errors.length && message && treatMessage() }

			<form onSubmit={onSubmit} autoComplete='off' method="POST">
				<InputLabel name='mail' type='mail' labelName='E-mail' value={values.mail} onChange={onChange}/>
				<InputLabel name='password' type='password' labelName='Senha' value={values.password} onChange={onChange} style={{marginTop: '20px'}} />

				<div className="boxPass">
					<Checkbox id='remember' name='remember' labelName='Lembrar-me'/>

					<Link to='/lembrar-senha' className="forgot dec">
						Esqueceu sua senha?
					</Link>
				</div>

				<button className="send" type="submit">
					Entrar
				</button>
			</form>
		</div>
	)
}
export default Login
