import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

// Contexts
import { GeneralProvider, FormsProvider, ThemeProvider, ModalProvider, MixPanelProvider } from './contexts';

// Routes
import Routes from '~/routes';

const App: React.FC = () => {
	return (
		<HelmetProvider>
			<MixPanelProvider>
				<ThemeProvider initialTheme="light">
					<GeneralProvider>
						<FormsProvider>
							<ModalProvider>
								<Routes />
							</ModalProvider>
						</FormsProvider>
					</GeneralProvider>
				</ThemeProvider>
			</MixPanelProvider>
		</HelmetProvider>
	)
}

export default App;
