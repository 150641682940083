import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import { Checkbox } from '~/components/Parts/Forms';

interface Props {
    setReadyPage: Function;
    data: any;
};

const FormEdit: React.FC<Props> = ({setReadyPage, data}): any => {
    const [units, setUnits]   = useState<any[]>([]);
    const [mailer, setMailer] = useState<any>(null);

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let infos: any = {[name]: value};
        if (name==='unit') data = {[name]: JSON.parse(value), unit_name: JSON.parse(value).name};

        setMailer({...mailer, ...infos});
    }

    function onChangeCheckbox(event: ChangeEvent<HTMLInputElement>) {
        const {name, checked} = event.target;
        let data: any = {[name]: (checked ? 1 : 0)};

        setMailer({...mailer, ...data});
    }

    function saveData () {
        const mailerData = mailer ? mailer : {};

        api.post('mailers/create', mailerData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReadyPage(false);
            Modal.setModalOpen(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    function cancel () {
        Modal.setModalOpen(false);
    }

    // Reder Select
    function renderUnits () {
        //console.log(JSON.stringify(mailer.unit))
        if (units.length>1) {
            return <select
                name="unit" id="unit" onChange={onChange}
                defaultValue={mailer && mailer.unit ? JSON.stringify(mailer.unit) : undefined}
            >
                <option value="">-- Selecione --</option>
                <optgroup label="Unidades">
                    {
                        units.map((el: any) => (
                            <option
                                key={`unit_${el.id}`}
                                value={JSON.stringify(el)}
                            >
                                {el.name}
                            </option>
                        ))
                    }
                </optgroup>
            </select>
        }

        // Single Unit
        return <input id="unit_mask" className='no-select' name="unit_mask" type="text" placeholder="Unidade" value={units[0].name} disabled />
    }

    // Get Units
    useEffect(() => {
        units.length===0 && api.get('clients/units', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            let unitsData: any = [];
            resp.data.map((el: any) => unitsData.push({id: el.id, name: el.name}));
            setUnits(unitsData.sort(sort_by('name')))
        });

        !mailer && data && setMailer(data)
    }, [mailer])

    return units.length>0 && (
        <>
            <div className="mailer_body">
                <div className="line">
                    <div className="name">
                        <label htmlFor="name">Nome</label>
                        <input id="name" name="name" type="text" placeholder="Nome" value={mailer ? mailer.name : ''} onChange={onChange} />
                    </div>

                    <div className="mail">
                        <label htmlFor="mail">Email</label>
                        <input id="mail" name="mail" type="text" placeholder="Email" value={mailer ? mailer.mail : ''} onChange={onChange} />
                    </div>
                </div>

                <div className="line">
                    <div className="unit">
                        <label htmlFor="unit">Unidade</label>
                        { renderUnits() }
                    </div>
                </div>

                <div className="line">
                    <div className="report center">
                        <label htmlFor="report">Relatórios</label>
                        <Checkbox id='report' name='economy_report' checked={mailer.economy_report ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="sales_off center">
                        <label htmlFor="sales_off">Garantia Fincanceira</label>
                        <Checkbox id='sales_off' name='ccee_sales_off' checked={mailer.ccee_sales_off ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="bkp_energy center">
                        <label htmlFor="bkp_energy">Energia de Reserva</label>
                        <Checkbox id='bkp_energy' name='ccee_backup_power' checked={mailer.ccee_backup_power ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>

                    <div className="ccee_fees center">
                        <label htmlFor="ccee_fees">Taxa CCEE</label>
                        <Checkbox id='ccee_fees' name='ccee_fees' checked={mailer.ccee_fees ? 1 : 0} onChange={onChangeCheckbox} />
                    </div>
                </div>
            </div>

            <div className="footerBottons">
                <button className="btn save" onClick={() => saveData()}><FaSave /></button>
                <button className="btn cancel" onClick={() => cancel()}>Cancelar</button>
            </div>
        </>
    );
}

export default FormEdit;