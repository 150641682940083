import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import path from 'path';
import api from '~/services/api';
import Upload from '~/services/upload';
import './styles.css';
import Button from "~/components/Parts/Forms/Button"

// Contexts
import { GeneralContext, MixPanelContext, ModalContext } from '~/contexts';

// Form
import FormBill from './Form';

export const useSingleBills = () => {
    const [ready, setReady]           = useState<Boolean>();
    const [bills, setBills]           = useState<any[]>([]);
    const [filesLoad, setFilesLoads]  = useState<any>(null);
    const [uploadFile, setUploadFile] = useState(false);
    const file                        = useRef<HTMLInputElement>(null);

    // Context
    const Modal         = useContext(ModalContext);
    const {buttonClick} = useContext(MixPanelContext);
    const {user, unit}  = useContext(GeneralContext);

    const isAdmin       = user.role === "admin" ? true : false

    function delBill (id: number) {
        buttonClick(user.id, 'Contas de energia', `Exclusão de fatura`)

        api.delete(`/bills/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false)
            setReady(false);
        })
    }

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.currentTarget.files && event.currentTarget.files.length>0) {
            setUploadFile(true)
            let err = _validatorExtention(event?.currentTarget.files);
            let item: any = {};

            if (!err) {
                item['id']            = event.currentTarget.files[0].lastModified;
                item['status']        = 'Em andamento';
                item['file_name']     = event.currentTarget.files[0].name;
                item['file_complete'] = event.currentTarget.files;

                setFilesLoads({...filesLoad, ...item});

                Upload.sendFile(user, unit, event.currentTarget.files[0])
                .then(resp => {
                    if(resp.res && resp.res.status === false){
                        openModalInfoBill()
                        setReady(false);
                        setTimeout(() => {
                            setUploadFile(false)
                        }, 1000)
                    }
                    if (resp.valid) {
                        setReady(false);
                        setTimeout(() => {
                            setUploadFile(false)
                        }, 1000)
                    } else {
                        //erro na leitura
                        openModalInfos(resp)
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                setUploadFile(false)
                alert('Extensão inválida')
            }
        }
    }

    function _validatorExtention (files: FileList) {
        let _validFileExtensions = [".pdf"];
        let err = false;
        Array.from(files).forEach(file => {
            let ext = path.extname(file.name).toLocaleLowerCase();
            if (!_validFileExtensions.includes(ext)) err = true;
        })
        return err;
    }
    function openModalInfoBill(){
        let body
        body = <div className="modal_file">
            <p><strong>Dados incompletos </strong><br/><br/>Problema na bandeira tarifária ou tarifa, isso pode gerar problemas no relatório de economia. <br/><br/> Nossa equipe já foi notificada e em breve o relatório de economia estará disponível.</p>
            </div>
        setUploadFile(false)
        Modal.setModalTitle('Problema ao gerar relatorio de economia')
        Modal.setModalBody(body)
        Modal.setModalOpen(true)
    }

    function openModalInfos (resp:any) {
        let body

        if(resp.data.date){
            body = <p>{resp.message}</p>
        }else{
            body =  <div className="modal_file">
            <p>Não foi possível avançar utilizando a leitura automática de sua fatura</p>
            <p>Não se preocupe, nossa equipe irá cadastrar as informações da sua fatura em até 1 dia útil e te avisaremos por e-mail assim que estiver pronto.</p>
            <div className="button">
                 <Button
                    type="button"
                    func={() => {
                        if (file && file.current) file.current.value='';
                        file && file.current?.click();
                        Modal.setModalOpen(false)
                    }}
                    text='Enviei a fatura errada'
                    className="ok"
                        />
                <Button
                    type="button"
                    className="edit"
                    func={() => helpAdmin(resp.data.id)}
                    text="Confirmar"
                />
            </div>
        </div>
        }

        setUploadFile(false)
        Modal.setModalTitle('Não conseguimos ler sua fatura')
        Modal.setModalBody(body)
        Modal.setModalOpen(true)
    }

    function helpAdmin(id: Number) {
        //openModaInfoHelp()
        Modal.setModalOpen(false)
        api.put(`/bills/help/${id}`, {mail:user.mail, unit: unit.name, userId: user.id}, {
            headers: { Authorization: user.token }
        }).then(resp => {
            //console.log(resp.data)
        })
    }



    function openModalBill () {
        Modal.setModalOpen(false);
        Modal.setModalClass('mbills');
        Modal.setModalTitle('Cadastro Manual de Fatura')
        Modal.setModalBody(<FormBill setReady={setReady} setErrorModal={openModalInfoBill}/>)
        Modal.setModalOpen(true)
    }

    useEffect(() => {
        !ready && unit && api.get(`bills/${unit.id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setTimeout(() => {
                setBills(resp.data);
                setReady(true);
        }, 1000)
        });
    }, [user, unit, ready]);

    return {
        ready,
        file,
        uploadFile,
        bills,
        delBill,
        onChange,
        openModalBill,
        setReady,
        isAdmin
    }
}
