
import React, { useContext, useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import { GeneralContext} from '~/contexts';

// Components
import UnitBox from '~/components/Boxs/Unit';

const MyUnits: React.FC = () => {
    const { unit, units, changeUnit } = useContext(GeneralContext);
    const [unitsList, setUnitsList]   = useState<any>(null);
    const [search, setSearch]         = useState('');

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = units && units.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.client_name && el.client_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.state && el.state.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.city && el.city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setUnitsList(clientsFilter);
    }

    useEffect(() => {
        !unitsList && units && setUnitsList(units);
    }, [units, search]);

    return (
        <>
            <div className='searchBox'>
                <input type="text" name="search" id="search" autoComplete='false' placeholder='Nome da unidade, Grupo ou Estado...' value={search} onChange={onChangeSearch} />
                { search !=='' ? <FaTimes onClick={() => {
                    setUnitsList(units)
                    setSearch('')
                }}/>
                : <FaSearch /> }
            </div>
            {unitsList && unitsList.map((el: any, idx: number) => <UnitBox key={`unit_${idx}`} data={el} change={() => unit.id!==el.id ? changeUnit(el.id) : false} />)}
        </>
    )
}

export default MyUnits;