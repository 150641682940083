let funcs = {
    onChange: function (data: any, name: string, value: string, upper?: boolean) {
        let newData: any = {[name]: (name==='active' ? (Number(value)===1 ? true : false) : (upper ? value.toUpperCase() : value))};

        // Check if is a object
        if (name.indexOf('.')>=0) {
            let nBase = name.split('.')
            if (nBase.length===2) newData = {[nBase[0]]: { [nBase[1]]: value}}
            else if (nBase.length===3) newData = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: value}}}
            else if (nBase.length===4) newData = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: { [nBase[3]]: value}}}}
        }

        return this._mergeDeep(data, newData)
    },

    onChangeFloat: function (data: any, name: string, value: string | number) {
        let newData: any = {[name]: value};

        // Check if is a object
        if (name.indexOf('.')>=0) {
            let nBase = name.split('.')
            if (nBase.length===2) newData = {[nBase[0]]: { [nBase[1]]: value}}
            else if (nBase.length===3) newData = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: value}}}
            else if (nBase.length===4) newData = {[nBase[0]]: { [nBase[1]]: { [nBase[2]]: { [nBase[3]]: value}}}}
        }

        return this._mergeDeep(data, newData)
    },

    _isObject: function (item: any) {
        return (item && typeof item==='object' && !Array.isArray(item));
    },

    _mergeDeep: function (target: any, ...sources: any) {
        if (!sources.length) return target;
        const source = sources.shift();

        if (this._isObject(target) && this._isObject(source)) {
            for (const key in source) {
                if (this._isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    this._mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }

        return target;
    }
}

export default funcs;