const providers = [
        1,  //EnelSP
        6,  // Celesc
        11, // Cemig
        17, // Copel
        32, // CPFL Paulista
        33, // CPFL Piratininga
        77  // ENEL Go
    ];

const checkProviders = {
    checkById: function (provider_id: number) {
        const ret = providers.find((el) => el === provider_id)
        return ret ? true : false
    },

}

export default checkProviders;