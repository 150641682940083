import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiPlus } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';
import { GroupsContext } from '~/contexts/forms/groups';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import GroupsBox from '~/components/Boxs/AdminGroups';
import FormGroups from './Form';

const AdminGroups: React.FC<any> = () => {
    const navigate          = useNavigate();
    const [ready, setReady]   = useState<Boolean>();
    const [groups, setGroups] = useState<any>([]);

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);
    const Groups = useContext(GroupsContext);

    function openModal (modal: string, data?: any) {
        let newUpdate      = false;
        let dataGroup: any = null;

        Modal.setModalOpen(false);
        Modal.setModalClass('mgroups');

        switch (modal) {
            case 'addGroup':
                Modal.setModalTitle('Adicionando Grupo')

                dataGroup = {
                    id       : 0,
                    name     : '',
                    active   : true
                }

                newUpdate = true;
                break;

            case 'editGroup':
                Modal.setModalTitle('Editando Grupo')

                if (data) {
                    dataGroup = {
                        id    : data.id ? parseInt(data.id) : 0,
                        name  : data.name ? data.name : '',
                        active: data.active
                    }
                }

                newUpdate = true;
                break;

            case 'deleteGroup':
                Modal.setModalClass('delete')
                Modal.setModalTitle('Excluindo Grupo')
                Modal.setModalBody(<>
                    <div>Deseja realmente excluir o grupo <i>"{ data?.name }"?</i></div>
                    <div className="alert">* Tenha cuidado, esse processo é irreversível!</div>

                    <footer>
                        <button id="btnDelete" type="button" onClick={() => Groups.remove(data.id, Modal, setReady)}>Excluir</button>
                        <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
                    </footer>
                </>)
                Modal.setModalOpen(true)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        if (newUpdate) {
            Modal.setModalBody(<FormGroups group={dataGroup} setReady={setReady} />)
            Modal.setModalOpen(true)
        }
    }

    useEffect(() => {
        // Check Permission
        if (user.role==='client') navigate('/404', {replace: true})
        else api.get('users/groups', {
            headers: { Authorization: user.token }
        }).then(resp => {
            setGroups(resp.data.groups);
            setReady(true);
        });
    }, [user, Modal.modalOpen]);

    return <Admin pageTitle="Gerenciamento de Grupos - Clientes Energizou" title="Gerenciamento"  subtitle="Grupos de Clientes" classMain='adm_groups'>
        {
            ready ? <>
                <div className="buttons">
                    <button
                        type="button"
                        className="addUser"
                        onClick={() => openModal('addGroup')}
                        data-for="tooltip_groups_page"
                        data-tip="Adicionar Grupo"
                    >
                        <FiPlus size="16" />
                    </button>
                </div>

                <div className="groups">
                    {
                        groups.map((el: any, idx: number) => (
                            <GroupsBox
                                key={`group_${idx}`}
                                {...el}
                                edit={() => openModal('editGroup', el)}
                                delete={() => openModal('deleteGroup', el)}
                            />
                        ))
                    }
                </div>

                <ReactTooltip
                    id="tooltip_groups_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
        }
    </Admin>
}

export default AdminGroups;