import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import sending from './images/sending.json';

const Loading: React.FC = () => {
    return <div className='loading sent'>
        <Lottie
            animationData={sending}
            loop play
            className="icon"
        />
    </div>;
}

export default Loading;