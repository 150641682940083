import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { FaTrash } from 'react-icons/fa';
import { FiPlus } from 'react-icons/fi';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Interfaces
import { UserState, FormProps } from '~/interfaces/UsersPermission';

const Form: React.FC<FormProps> = ({permission, groups, units, setReady}) => {
    const [tab, setTab]           = useState<string>(!Array.isArray(permission) && permission.client_id ? 'client' : 'unit');
    const [userID, setUserID]     = useState<any>(null);
    const [selUnit, setSelUnit]   = useState<any>('');
    const [userData, setUserData] = useState<UserState[] | UserState | null>(null);

    // Contexts
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let data: any = {[name]: parseInt(value)};

        if (tab==='client') {
            data.unit_id = 0;
        } else if (tab==='unit') {
            data.client_id = 0;
        }

        setUserData({user_id: userID, ...data});
    }

    function onChangeUnits(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {value} = event.target;
        setSelUnit(value);
    }

    function addUnit() {
        if (_checkUnit()) {
            let info = { user_id: userID, client_id: 0, unit_id: parseInt(selUnit) };
            let newData: any = null;


            if (Array.isArray(userData)) {
                let index = userData.findIndex((el: UserState) => el.unit_id===0);
                if (index>=0) userData.splice(index, 1);

                if (userData.length>0) {
                    newData = JSON.parse(JSON.stringify(userData));
                    newData.push(info);
                } else {
                    newData = info;
                }
            } else if (!Array.isArray(userData) && userData && userData.unit_id!==0) {
                let currData = JSON.parse(JSON.stringify(userData));
                newData      = [];
                newData.push(currData);
                newData.push(info);
            } else {
                newData = info;
            }

            setUserData(newData);
        }
    }

    function removeUnit(unit: UserState) {
        let newData: any = null;

        if (Array.isArray(userData)) {
            newData    = JSON.parse(JSON.stringify(userData));
            let index  = newData.findIndex((el: UserState) => el.unit_id===unit.unit_id);
            newData.splice(index, 1);

            if (newData.length===1) newData = newData[0];
        } else {
            newData = {
                user_id: userID,
                client_id: 0,
                unit_id: 0
            };
        }

        setUserData(newData);
    }

    function _checkUnit() {
        if (userData && selUnit!=='') {
            if (Array.isArray(userData)) {
                if (userData.length===0) return true;

                let check = userData.filter((el: UserState) => el.user_id===userID && el.unit_id===parseInt(selUnit));
                return check.length===0 ? true : false;
            } else {
                return userData.user_id===userID && userData.unit_id===parseInt(selUnit) ? false : true;
            }
        }

        return false;
    }

    function save() {
        api.post(`users/permissions`, userData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    useEffect(() => {
        if (permission && !userID) setUserID(!Array.isArray(permission) ? permission.user_id : permission[0].user_id);
        if (!userData) setUserData(permission);
    }, []);

    return (
        <>
            <div className="header">
                <span className={`tab${ tab==='client' ? ' active' : '' }`} onClick={() => { setTab('client'); setSelUnit('') }}>
                    Grupo
                </span>

                <span className={`tab${ tab==='unit' ? ' active' : '' }`} onClick={() => setTab('unit')}>
                    Unidade
                </span>
            </div>

            <div className="permissionBox">
                {
                    tab==='client' ? (
                        <select
                            name="client_id" id="groups"
                            defaultValue={tab==='client' && !Array.isArray(permission) && permission.client_id ? permission.client_id : ''}
                            onChange={onChange}
                        >
                            <option value="">Nenhum</option>
                            <optgroup label="Grupos">
                                {
                                    groups && groups.map((el: any) => (
                                        <option key={`key_groups_${el.id}`} value={el.id}>{ el.name }</option>
                                    ))
                                }
                            </optgroup>
                        </select>
                    ) : <></>
                }

                {
                    tab==='unit' ? (
                        <>
                            <div className="unitLine">
                                <select
                                    name="unit_id" id="units"
                                    defaultValue=""
                                    onChange={onChangeUnits}
                                >
                                    <option value="">Selecione uma Unidade</option>
                                    <optgroup label="Unidades">
                                        {
                                            units && units.map((el: any) => (
                                                <option key={`key_units_${el.id}`} value={el.id}>{ el.name }</option>
                                            ))
                                        }
                                    </optgroup>
                                </select>

                                <button type="button" className="addUnit" onClick={() => addUnit()} disabled={_checkUnit() ? false : true}>
                                    <FiPlus size="16" />
                                </button>
                            </div>

                            <div className="units">
                                {
                                    userData ?
                                        Array.isArray(userData) || (!Array.isArray(userData) && userData.unit_id>0) ?
                                            Array.isArray(userData) ? userData.length>0 && userData.map((el: UserState) => (
                                                <div className="lineUnit" key={`unit_${el.unit_id}`}>
                                                    <span>{ units.filter((x: any) => x.id===el.unit_id)[0]['name'] }</span>

                                                    <button type="button" className="delUnit" onClick={() => removeUnit(el)}>
                                                        <FaTrash size={12} />
                                                    </button>
                                                </div>
                                            )) : (
                                                <div className="lineUnit">
                                                    <span>{ units.filter((x: any) => x.id===userData.unit_id)[0]['name'] }</span>

                                                    <button type="button" className="delUnit" onClick={() => removeUnit(userData)}>
                                                        <FaTrash size={12} />
                                                    </button>
                                                </div>
                                            )
                                        : <div className="noUnit">
                                            Não há unidades selecionadas no momento
                                        </div>
                                    : <></>
                                }
                            </div>
                        </>
                    ) : <></>
                }
            </div>

            <footer>
                <button id="btnSave" type="button" onClick={() => save()}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </>
    );
}

export default Form;