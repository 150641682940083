import React, { useContext, useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { GeneralContext } from '~/contexts';
import api from '~/services/api';
import './styles.css';

// Components
import Admin from '~/components/Admin';
import LoadingSimple from '~/components/Default/Loading/Simple';
import BillHistoryBox from '~/components/Boxs/Bill/history';


const  ReadingHistory: React.FC<any> = (props: any) => {
    const { user, breadcrumbs, setBreadcrumbs } = useContext(GeneralContext);
    const [ready, setReady] = useState(false);
    const [errors, setErrors] = useState<any>(null);
    const [errorsList, setErrorsList] = useState<any>([]);
    const [search, setSearch] = useState('');
    const [billReadingHistory, setBillReadingHistory] = useState('1');
    const [messages, setMessages] = useState<any>(<></>);
    const [units, setUnits] = useState<any>([]);

    function onChangeSearch(event: any) {
        let data = event.target.value;
        let errorsFilter = errorsList.filter((el: any) => {
            const unitName = el.unit?.name || '';
            const providerName = el.provider || '';

            return unitName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 ||
                   providerName.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0;
        });
        if(data === ""){
            const filteredComErrors = errorsList.filter((el: any) => el.status === "Waiting");
            setErrors(filteredComErrors);
        }else{
            setErrors(errorsFilter);
        }

        setErrors(errorsFilter)
        setSearch(data)
    }

    function onChangeSelect(event: any) {
        const selectedValue = event.target.value;
        setBillReadingHistory(selectedValue)

        if (selectedValue === '0') {
            const filteredErrors = errorsList.filter((el: any) => el.status === "Read");
            setErrors(filteredErrors);
            setReady(true)
        } else if (selectedValue === '1') {
            const filteredComErrors = errorsList.filter((el: any) => el.status === "Waiting");
            setErrors(filteredComErrors);
            setReady(true)
        } else {
            setErrors(errorsList);
            setReady(true)
        }
    }

    useEffect(() => {
        if (errors === null) {
            setReady(false)

            api.get(`/bills/all/bill-history`, { headers: { Authorization: user.token } })
                .then(resp => {
                    const valueBill = resp.data.filter((el: any) => el.bill_number === null || (el.bill_number && !el.bill_number.includes("Manual_")));
                    console.log("🚀 ~ useEffect ~ valueBill:", valueBill)

                    const filtered = valueBill.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

                    const filteredWaiting = filtered.filter((el: any) => el.status === "Waiting");

                    setErrorsList(filtered);
                    setErrors(filteredWaiting);
                    setReady(true);
                    //console.log("🚀 ~ useEffect ~ filtered:", filtered)
                })
        }
    }, [user, ready, errors]);


    // Breadcrumbs
    useEffect(() => {
        ready && !breadcrumbs && setBreadcrumbs({
            curr: 'Historico de contas dos Clientes',
            links: [
                { name: 'Lista de Clientes', url: '/admin/s/clientes' }
            ]
        });
    }, [ready])

    // Unmount
    useEffect(() => () => setBreadcrumbs(null), [])

    return <Admin pageTitle="Administrador - Clientes - Historico de contas" title="Gerenciamento do historico de contas" className="cliBillError">
        {
            ready ? (<>
                <div className="header">
                    <div className="messageInfo">
                        {messages}
                    </div>
                    <div className="searchBox">

                        <select id="faturas" name="faturas" defaultValue="1" onChange={onChangeSelect}>
                            <option value="2" >Todas Faturas</option>
                            <option value="1" >Faturas em leitura</option>
                            <option value="0">Faturas concluidas</option>
                        </select>

                        <input type="text" name="search" id="search" placeholder='Filtrar Contas...' value={search} onChange={onChangeSearch} disabled={ready && errors ? false : true} />

                        {search !== '' && <button onClick={() => {
                            setSearch('')
                            //setErrors(errorsList.filter((el: any) => el.help_admin == parseInt('1')))
                        }}>
                            <FaTimes />
                        </button>}

                    </div>
                </div>

                <div className="billsErrorsBox">
                    <header>
                        <div className="blank"></div>
                        {/* <div className="client">Unidade</div>
                        <div className="provider">Distribuidora</div>
                        <div className="state">Status</div>
                        <div className="date">Data do Envio</div> */}
                        <div className="btns"></div>
                    </header>

                    <section>
                        {
                            errors && errors.length > 0 ?
                                errors.map((el: any) => (
                                    <BillHistoryBox
                                        key={`box_${el.id}`}
                                        bill_id={el.id}
                                        pdf={el.pdf_path}
                                        path={el.path}
                                        companyName={el.units_name}
                                        setBillError={setErrors}
                                        date={el.reference_date}
                                        Bill={el}
                                        setMessages={setMessages}
                                    />
                                )) : <div className='noData'>Não foi possível localizar contas com erros para o clientes</div>
                        }
                    </section>
                </div>
            </>) : <LoadingSimple />
        }
    </Admin>;
}

export default ReadingHistory;