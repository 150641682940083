import { useContext, createContext, ReactNode } from "react";
import api from "~/services/api";

// Interfaces
import { UserState, ProfileState } from './interfaces'

// Contexts
import { GeneralContext } from '~/contexts';

interface UsersContextData {
    save       : Function;
    remove     : Function;
    saveProfile: Function;
}

interface UsersProviderProps {
    children: ReactNode;
}

export const UsersContext = createContext({} as UsersContextData)

export function UsersProvider ({ children }: UsersProviderProps) {
    const {user} = useContext(GeneralContext);

    async function save (userData: UserState, Modal: any, setReady: Function) {
        api.post('users', userData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error)
            }
        });
    }

    async function remove (id: number, Modal: any, setReady: Function) {
        api.delete(`users/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    async function saveProfile (data: ProfileState, Modal: any, updateUser: Function, setChange: Function) {
        const profileData = _treatProfileData(data);
        api.post('users/profile', profileData, {
            headers: { Authorization: user.token, 'Content-Type': 'multipart/form-data;' }
        }).then(resp => {
            Modal.setModalOpen(false);
            updateUser(resp.data);
            if (data.password && data.password_confirm && data.password!=='' && data.password_confirm!=='') setChange(true);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    function _treatProfileData (data: ProfileState) {
        const formData = new FormData();
        Object.entries(data).map((el: any) => {
            if (el[0]==='image' && el[1]!==null) formData.append("image", el[1][0])
            else formData.append(el[0], el[1]);
        })

        return formData;
    }

    return (
        <UsersContext.Provider value={{save, remove, saveProfile}}>
            { children }
        </UsersContext.Provider>
    )
}