import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { FaSave } from 'react-icons/fa';
import { FiEdit2, FiTrash2, FiSave, FiX } from 'react-icons/fi';
import sort_by from '~/utils/sort';
import api from '~/services/api';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import { Checkbox } from '~/components/Parts/Forms';

interface Props {
    setReadyPage: Function;
};

const FormNew: React.FC<Props> = ({setReadyPage}): any => {
    const [units, setUnits]         = useState<any[]>([]);
    const [mailers, setMailers]     = useState<any>([]);
    const [mailersV, setMailersV]   = useState<any>();
    const [mailersPO, setMailersPO] = useState<any>();

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let data: any = {[name]: value};
        if (name==='unit') data = {[name]: JSON.parse(value), unit_name: JSON.parse(value).name};

        setMailersV({...mailersV, ...data});
    }

    function onChangeCheckbox(event: ChangeEvent<HTMLInputElement>) {
        const {name, checked} = event.target;
        let data: any = {[name]: (checked ? 1 : 0)};

        setMailersV({...mailersV, ...data});
    }

    function addMailer() {
        let data = {id: 0, name: '', mail: '', unit: {id: 0, name: 'Selecione'}, unit_name: ''};
        if (units.length===1) data  = {...data, unit: units[0], unit_name: units[0].name}
        let datas = JSON.parse(JSON.stringify(mailers));

        // Add Item
        datas.push(data);
        datas.sort(sort_by('unit_name', {name: 'name'}));

        // Set new items
        setMailers(datas);
    }

    function editMailer(id: number, elem: any) {
        let item = document.querySelector(`div[data-rel="${id}_${elem.name}_${elem.mail}_${elem.unit.id}"]`);
        item?.classList.add('active');

        setMailersV(elem);
        setMailersPO(elem);
    }

    function deleteMailer(elem: any) {
        let datas = JSON.parse(JSON.stringify(mailers));
        let index = datas.findIndex((q: any) => q.name===elem.name && q.mail===elem.mail && q.unit.id===elem.unit.id);

        // Remove Item
        datas.splice(index, 1);
        datas.sort(sort_by('unit_name', {name: 'name'}));

        // Set new items
        setMailers(datas);
    }

    function saveMailer(id: number, elem: any) {
        let datas = JSON.parse(JSON.stringify(mailers));
        let item  = document.querySelector(`div[data-rel="${id}_${elem.name}_${elem.mail}_${elem.unit.id}"]`);
        item?.classList.remove('active');

        // Change Item
        let index = datas.findIndex((q: any) => q.name===mailersPO.name && q.mail===mailersPO.mail && q.unit.id===mailersPO.unit.id);
        datas.splice(index, 1, mailersV);
        datas.sort(sort_by('unit_name', {name: 'name'}));

        // Remove espaços no início e no final do mail
        datas.forEach((data: any) => {
            data.mail = data.mail.trim();
        });

        // Set Mailers
        setMailers(datas);

        // Clear Data Items
        setMailersV(null);
        setMailersPO(null);
    }

    function cancelMailer(id: number, elem: any) {
        let item = document.querySelector(`div[data-rel="${id}_${elem.name}_${elem.mail}_${elem.unit.id}"]`);
        item?.classList.remove('active');
        setMailersV(null);
    }

    function saveData () {
        const mailersData = mailers.length>0 ? mailers : [];

        api.post('mailers/create', mailersData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setReadyPage(false);
            Modal.setModalOpen(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    // Reder Select
    function renderUnits () {
        if (units.length>1) {
            return <select
                name="unit" id="unit" onChange={onChange}
                defaultValue={mailersV && mailersV.unit ? JSON.stringify(mailersV.unit) : undefined}
            >
                <option value="">-- Selecione --</option>
                <optgroup label="Unidades">
                    {
                        units.map((el: any) => (
                            <option
                                key={`unit_${el.id}`}
                                value={JSON.stringify(el)}
                                selected={mailersV && mailersV.unit && JSON.stringify(mailersV.unit)===JSON.stringify(el)}
                            >
                                {el.name}
                            </option>
                        ))
                    }
                </optgroup>
            </select>
        }

        // Single Unit
        return <input id="unit_mask" className='no-select' name="unit_mask" type="text" placeholder="Unidade" value={units[0].name} disabled />
    }

    // Get Units
    useEffect(() => {
        units.length===0 && api.get('clients/units', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            let unitsData: any = [];
            resp.data.map((el: any) => unitsData.push({id: el.id, name: el.name}));
            setUnits(unitsData.sort(sort_by('name')))
        });
    }, [mailers])

    return units.length>0 && (
        <>
            <div className="header">
                <div className="infos">
                    <div className="name">Nome</div>
                    <div className="mail">Email</div>
                    <div className="unit">Unidade</div>
                    <div className="report">Relatório</div>
                    <div className="sales_off">Garantia</div>
                    <div className="bkp_energy">Reserva</div>
                    <div className="ccee_fees">Taxa CCEE</div>
                </div>

                <div className="buttons"></div>
            </div>

            <div className="mailerList">
                {
                    mailers.length>0 ?
                        mailers.map((el: any, idx: number) => (
                                <div className="mailer_line" key={`${idx}_${el.name}_${el.mail}_${el.unit.id}`} data-rel={`${idx}_${el.name}_${el.mail}_${el.unit.id}`}>
                                    <div className="infos">
                                        <div className="item">
                                            <div className="name" title={el.name && el.name}>{ el.name ? el.name : '-' }</div>
                                            <div className="mail" title={el.mail && el.mail}>{ el.mail ? el.mail : '-' }</div>
                                            <div className="unit" title={el.unit && el.unit.name}>{ el.unit ? el.unit.name : '-' }</div>
                                            <div className="report" title="Relatório">{ el.economy_report ? "Sim" : 'Não' }</div>
                                            <div className="sales_off" title="Garantia Financeira">{ el.ccee_sales_off ? "Sim" : 'Não' }</div>
                                            <div className="bkp_energy" title="Energia de Reserva">{ el.ccee_backup_power ? "Sim" : 'Não' }</div>
                                            <div className="ccee_fees" title="Taxa CCEE">{ el.ccee_fees ? "Sim" : 'Não' }</div>
                                        </div>

                                        <div className="edit">
                                            <div className="name">
                                                <input id="name" name="name" type="text" placeholder="Nome" value={mailersV ? mailersV.name : ''} onChange={onChange} />
                                            </div>

                                            <div className="mail">
                                                <input id="mail" name="mail" type="text" placeholder="Email" value={mailersV ? mailersV.mail : ''} onChange={onChange} />
                                            </div>

                                            <div className="unit">
                                                { renderUnits() }
                                            </div>

                                            <div className="report">
                                                <Checkbox id='report' name='economy_report' checked={el.economy_report ? 1 : 0} onChange={onChangeCheckbox} />
                                            </div>

                                            <div className="sales_off">
                                                <Checkbox id='sales_off' name='ccee_sales_off' checked={el.ccee_sales_off ? 1 : 0} onChange={onChangeCheckbox} />
                                            </div>

                                            <div className="bkp_energy">
                                                <Checkbox id='bkp_energy' name='ccee_backup_power' checked={el.ccee_backup_power ? 1 : 0} onChange={onChangeCheckbox} />
                                            </div>

                                            <div className="ccee_fees">
                                                <Checkbox id='ccee_fees' name='ccee_fees' checked={el.ccee_fees ? 1 : 0} onChange={onChangeCheckbox} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="buttons">
                                        <div className="item">
                                            <button className="edit" onClick={() => editMailer(idx, el)} disabled={(!mailersV ? false : true)}>
                                                <FiEdit2 size={16} />
                                            </button>

                                            <button className="del" onClick={() => deleteMailer(el)} disabled={(!mailersV ? false : true)}>
                                                <FiTrash2 size={16} />
                                            </button>
                                        </div>

                                        <div className="edit">
                                            <button className="save" onClick={() => saveMailer(idx, el)}>
                                                <FiSave size={16} />
                                            </button>

                                            <button className="cancel" onClick={() => cancelMailer(idx, el)}>
                                                <FiX size={16} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        ))
                    : <></>
                }
            </div>

            <div className="footerBottons">
                <button className="btn add" onClick={() => addMailer()}>Adicionar novo email</button>
                <button className="btn save" onClick={() => saveData()}><FaSave /></button>
            </div>
        </>
     );
}

export default FormNew;