import { useContext, useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import api from '~/services/api';
import dates from '~/utils/dates';
import './styles.css';

// // Contexts
import { GeneralContext } from '~/contexts';
import checkProviders from '~/utils/checkProviders';
import { MixPanelContext } from '~/contexts/mixPanel';

export const useSingleReport = () => {
	const {user, unit}   = useContext(GeneralContext);
    const {buttonClick}   = useContext(MixPanelContext);

	const navigate       = useNavigate();
	const checkProvider  = checkProviders.checkById(unit?.provider?.id);
	let   params         = useParams();
	let   yearMonth: any = params.yearMonth;

    // Dates
    const months         = dates.getMonthsYear(true);
    const monthCurr      = dates.getCurrMonthAPI();

    // States
    const [ready, setReady]               = useState<Boolean>(false);
    const [ymSel, setYearMonth]           = useState<number[] | null>(yearMonth ? yearMonth.split('-') : null);
    const [unitCalc, setUnitCalculate]    = useState<any>();
    const [statusReq, setStatusReq]    = useState<any>();
    const [downReady, setDownReady]       = useState<Boolean>(true);
    const [downPDFReady, setDownPDFReady] = useState<Boolean>(true);

    // Functions
    function exportExcel() {
        setDownReady(false);

        api.get(`export/report/excel/${unit.id}${ ymSel ? `/${ymSel[0]}-${ymSel[1]}` : ''}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url      = window.URL.createObjectURL(new Blob([resp.data]));
            let link     = document.createElement('a');
            let fileName = `Relatório Cativo x Mercado Livre - ${yearMonth && ymSel ? dates._getNameMonth(ymSel[1]-1) + ' de ' + ymSel[0] : dates._getNameMonth(monthCurr.month-1) + ' de ' + monthCurr.year} - ${unit.name}.xlsx`;

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setDownReady(true);
            buttonClick(user.id, 'Relatório', 'Export do relatório Excel completo')
        }).catch(err => {
            buttonClick(user.id, 'Relatório', 'Falha. Export do relatório Excel incompleto')
            console.log(err);
        });
    }

    function exportPDF() {
        setDownPDFReady(false);

        api.get(`export/report/pdf/${unit.id}${ ymSel ? `/${ymSel[0]}-${ymSel[1]}` : ''}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then((resp: any) => {
            let url      = window.URL.createObjectURL(new Blob([resp.data], {type: 'application/pdf'}));
            window.open(url, '_blank');
            setDownPDFReady(true);
            buttonClick(user.id, 'Relatório', 'Export do relatório PDF completo')

        }).catch(err => {
            buttonClick(user.id, 'Relatório', 'Falha. Export do relatório PDF incompleto')

            console.log(err);
        });
    }

    async function changeMonth(event: any) {
        let value = event.target.value;

        if (value!=='') {
            let yearMonth = value.split('-');

            if (Number(yearMonth[0])===monthCurr.year && Number(yearMonth[1])===monthCurr.month) {
                navigate(`/relatorio`)
                getReport(null);
                yearMonth = null;
            } else {
                navigate(`/relatorio/${yearMonth[0]}-${yearMonth[1]}`)
                getReport(value);
            }

            setYearMonth(yearMonth);
            setUnitCalculate(undefined);
            setReady(false);
        }

        buttonClick(user.id, 'Relatório', 'Mês de referência')

    }

    function getReport(yearMonth:string | null){
        let url = `clients/unit/report/${unit.id}${ yearMonth ? `/${yearMonth}` : ''}`;

        api.get(url, {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            setUnitCalculate(resp.status===200 ? resp.data.calculate : true);
            setStatusReq(resp.status)
            setReady(true);
        }).catch((err: any) => {
            if (err.response.status===400) {
                setUnitCalculate({});
                setReady(true);
            } else {
                console.log(err.response);
            }
        });
    }

    useEffect(() => {
        !ready && getReport(yearMonth)

    }, [user]);

    return({
        ready,
        checkProvider,
        unit,
        unitCalc,
        downReady,
        downPDFReady,
        yearMonth,
        months,
        monthCurr,
        exportPDF,
        exportExcel,
        changeMonth,
        statusReq,
    });
}