import api from "./api";

const Upload = {
    sendFile: async function (user: any, unit:any, data: Blob) {
        let ret: any = false
        let formData = new FormData()
        formData.append('file', data)
        formData.append('unit', unit.id)
        formData.append('unitName', unit.name)

        await api.post('bills/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': user.token
            }
        }).then(resp => {
            ret = {valid:true, res:resp.data.res}
        }).catch(err => {
            ret = {valid:false, data:err.response.data, message: err.response.data.message}
        });

        return ret
    },
}

export default Upload;