import React from 'react';
import Lottie from 'react-lottie-player';
import './styles.css';

// Images
import loadingLight from './images/loading_light.json';

const Loading: React.FC = () => {
    return <div className='loading'>
        <Lottie
            animationData={loadingLight}
            loop play
            className="icon"
        />
    </div>;
}

export default Loading;