import React, { ChangeEvent, useEffect, useState, useContext, useRef } from 'react';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';
import { UsersContext } from '~/contexts/forms/users';

// Components
import Loading from '~/components/Default/Loading';

// Images
import user_image from '~/assets/images/user.jpg';

const Form: React.FC<any> = ({profile, setChange}) => {
    const [profileData, setProfileData] = useState<any>(null);
    const [preview, setPreview]         = useState<string | null>(null);
    const fileEl                        = useRef<HTMLInputElement>(null);

    // Context
    const {updateUser} = useContext(GeneralContext);
    const Users        = useContext(UsersContext);
    const Modal        = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement>) {
        const {name, value} = event.target;
        let data;

        if (name==='image') {
            data = {[name]: event.currentTarget.files};
            if (event.currentTarget.files) setPreview(window.URL.createObjectURL(event.currentTarget.files[0]))
        } else {
            data = {[name]: value};
        }

        setProfileData({...profileData, ...data})
    }

    function _treatImage() {
        if (preview) return preview;
        return profile.image_url ? profile.image_url : user_image;
    }

    useEffect(() => {
        if (!profileData) setProfileData(profile)
    }, [profile, profileData])

    return profileData ? (<>
        <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editProfile">
            <div className="imageBox">
                <img src={_treatImage()} alt={`Imagem do usuário ${profileData.name}`} onClick={() => fileEl && fileEl.current?.click()} />
                <input onChange={onChange} type="file" name="image" id="image" ref={fileEl} />
            </div>

            <hr />

            <div className="input-group no-margin">
                <label htmlFor="name">Nome</label>
                <input id="name" name="name" type="text" placeholder="Nome" value={profileData.name} onChange={onChange} />
            </div>

            <div className="input-group">
                <label htmlFor="mail">Email</label>
                <input id="mail" name="mail" type="mail" placeholder="Email" value={profileData.mail} disabled={true} />
            </div>

            <hr />

            <div className="form-group no-margin">
                <div className="input-group mg-none">
                    <label htmlFor="mail">Senha</label>
                    <input id="password" name="password" type="password" placeholder="Senha" value={profileData.password} onChange={onChange} autoComplete='new-password' />
                </div>

                <div className="input-group mg-none">
                    <label htmlFor="mail">Confirmar Senha</label>
                    <input id="password_confirm" name="password_confirm" type="password" placeholder="Senha" value={profileData.password_confirm} onChange={onChange} autoComplete='new-password' />
                </div>
            </div>

            <small>Inserir os dados de senha apenas se desejar troca-la</small>
        </form>

        <footer>
            <button id="btnSave" type="button" onClick={() => Users.saveProfile(profileData, Modal, updateUser, setChange)}>Salvar</button>
            <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
        </footer>
    </>) : <Loading />;
}

export default Form;