import dateFormat  from 'dateformat';

const dates = {
    getDateFormatted: function (date: string | Date, format?: string) {
        return dateFormat(date, format ? format : 'dd-mm-yyyy');
    },

    getDateDBFormatted:function (date: string) {
        let nDate = new Date(date);

        let year  = dateFormat(nDate, 'yyyy'),
            month = dateFormat(nDate, 'mm'),
            day   = dateFormat(nDate, 'dd');

        return year + '-' + month + '-' + day + 'T03:00:00.000Z';
    },

    getDate: function (date: string) {
        date = date.indexOf('T')>=0 ? date.split('T')[0] : date;
        date = date + ' 00:00:00';

        let year  = Number(dateFormat(date, 'yyyy')),
            month = Number(dateFormat(date, 'mm')),
            day   = Number(dateFormat(date, 'dd'));

        return {year, month, day};
    },

    getReport: function () {
        let date   = new Date();

        let year   = Number(dateFormat(date, "yyyy"));
        let month  = Number(dateFormat(date, "mm"));

        if (month===1) {
            year  = year-1;
            month = 12;
        } else {
            month = month-1;
        }

        return year + '-' + month;
    },

    getDay: function () {
        let date = new Date(),
            day = date.getDate()-1;
        return (day<10 ? '0' + day : day);
    },

    getDaysMonth: function (year: number, month: number) {
        let monthsVector = (
            (year%400===0) || (year%4===0 && year%100!==0) ?
            [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31] :
            [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        );

        return monthsVector[month-1];
    },

    getMonth: function () {
        let date = new Date();
        return this._getNameMonth(date.getMonth());
    },

    getYear: function () {
        let date = new Date();
        return date.getUTCFullYear();
    },

    getMonthYear: function () {
        let date  = new Date(),
            month = this._getNameMonth(date.getMonth()),
            year  = date.getUTCFullYear();

        return month + '/' + year;
    },

    getMonthsYear: function (old: boolean = false, dateBase?: string) {
        let date  = dateBase ? new Date(dateBase) : new Date(),
            month = date.getMonth(),
            year  = date.getUTCFullYear(),
            ret   = [];

        if (month>=1) {
            for (let i=month; i>=0; i--) {
                ret.push({year, month: i+1, name: this._getNameMonth(i)});
            }

            if (month<12) {
                let qtty      = 36-(old ? ret.length-1 : ret.length);
                let monthInit = 12;

                for (let f=qtty; f>0; f--) {
                    ret.push({year: (year-1), month: monthInit, name: this._getNameMonth(monthInit-1)});
                    monthInit--;
                    if(monthInit === 0) {
                        year--
                        monthInit = 12
                    }
                }
            }
        } else {
            if (!old) {
                for (let y=month; y>=0; y--) {
                    ret.push({year: year, month: y+1, name: this._getNameMonth(y)});
                }
            }

            for (let z=11; z>=0; z--) {
                ret.push({year: (year-1), month: z+1, name: this._getNameMonth(z)});
            }
        }

        return ret;
    },

    getMonthsLastYear: function (dateBase?: string) {
        let date  = dateBase ? new Date(dateBase + ' 03:00:00') : new Date(),
            month = date.getMonth(),
            year  = date.getUTCFullYear(),
            ret   = [];

        if (month>=1) {
            for (let i=month; i>=0; i--) {
                ret.push({year, month: i+1, name: this._getNameMonth(i)});
            }

            if (month<12) {
                let qtty      = 12-ret.length
                let monthInit = 12

                for (let f=qtty; f>0; f--) {
                    ret.push({year: (year-1), month: monthInit, name: this._getNameMonth(monthInit-1)});
                    monthInit--
                    if(monthInit===0) {
                        year--
                        monthInit = 12
                    }
                }
            }
        } else {
            for (let z=11; z>=0; z--) {
                ret.push({year: (year-1), month: z+1, name: this._getNameMonth(z)});
            }
        }

        return ret;
    },

    getCurrMonthAPI: function () {
        let date     = new Date(),
            ret: any = { year: date.getUTCFullYear(), month: date.getMonth()};

        if (ret.month===0) {
            ret.year  = ret.year-1;
            ret.month = 12;
        }

        return ret;
    },

    getDateCharts: function (date: Date | string) {
        let day            = dateFormat(date, 'dd');
        let year           = dateFormat(date, 'yyyy');
        let month          = dateFormat(date, 'mm');
        let monthName      = this._getNameMonth(parseInt(month));
        let monthNameShort = this._getShortNameMonth(parseInt(month));

        return {
            day,
            year,
            month,
            monthName: {
                full: monthName,
                short: monthNameShort
            }
        }
    },

    _getNameMonth: function (month: number, curr=false) {
        if (curr) month = month-1;

        let monthArr = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
        ];


        return monthArr[month];
    },

    _getShortNameMonth: function (month: number) {
        let monthArr = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez"
        ];

        return monthArr[month-1];
    },

    _getDateByDate: function (date: Date | string) {
        let day            = dateFormat(date, 'dd');
        let year           = dateFormat(date, 'yyyy');
        let month          = dateFormat(date, 'mm');
        let monthName      = this._getNameMonth(parseInt(month), true);

        return {year, month, monthName, day};
    }
}

export default dates;