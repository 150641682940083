import React, { Ref } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

// Props
interface Props {
    type: string;
    link?: string;
    func?: Function;
    icon?: any;
    text?: any;
    state?:string;
    className: string;
    disabled?: boolean;
    refButton?: Ref<any>;
    children?: Element
}

const Button:React.FC<any> = (props: Props) => {
    return props.type==='link' && props.link && !props.disabled ? (
        <Link id="btns" to={props.link} className={props.className ? props.className : ''} ref={props.refButton} state={props.state} onClick={() => props.func ? props.func() : null}>
            { props.icon && props.icon }
            { props.text && <span dangerouslySetInnerHTML={{ __html: props.text}} /> }
            { props.children && props.children }
        </Link>
    ) : (
        <button id="btns" type={props.type==='button' || props.type==='submit' ? props.type : 'button'} className={props.className ? props.className : ''} onClick={() => props.func ? props.func() : null} disabled={props.disabled} ref={props.refButton}>
            { props.icon && props.icon }
            { props.text && <span dangerouslySetInnerHTML={{ __html: props.text}} /> }
        </button>
    )
}

export default Button;