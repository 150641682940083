import React, { useState, useContext, useEffect } from 'react';
import { FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import api from '~/services/api';
import sort_by from '~/utils/sort';
import './styles.css';

// Contexts
import { GeneralContext, ModalContext } from '~/contexts';

// Components
import Admin from '~/components/Admin';
import { LoadingSingle } from '~/components/Default/Loading';
import MailBox from '~/components/Boxs/Mailers/Mails';
import FormMail from './Form/New';
import FormMailEdit from './Form/Edit';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';

const AdminMailer: React.FC<any> = () => {
    const [ready, setReady] = useState<Boolean>(false);
    const [mailers, setMailers]   = useState<any[]>([]);
    const [mailersList, setMailersList] = useState<any>([]);
    const [search, setSearch]       = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 14;

    // Context
    const {user} = useContext(GeneralContext);
    const Modal  = useContext(ModalContext);

    function onChangeSearch (event: any) {
        let data = event.target.value;
        let clientsFilter = mailersList.filter((el: any) =>
            el.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0 ||
            (el.mail && el.mail.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0) ||
            (el.unit.name && el.unit.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(data.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))>=0)
            ? true : false
        )

        setSearch(data)
        setMailers(clientsFilter);
    }

    function openModal(modal: any, data?: any) {
        Modal.setModalOpen(false);

        switch (modal) {
            case 'addMail':
                Modal.setModalClass('maddmailers');
                Modal.setModalTitle('Adicionar novo email');
                Modal.setModalBody(<FormMail setReadyPage={setReady} />)
                break;

            case 'editMail':
                Modal.setModalClass('meditmailers');
                Modal.setModalTitle('Editar email');
                Modal.setModalBody(<FormMailEdit data={data} setReadyPage={setReady} />)
                break;

            default:
                Modal.setModalClass(null);
                Modal.setModalTitle('');
                Modal.setModalBody(<></>);
                break;
        }

        Modal.setModalOpen(true)
    }

    async function deleteMail (id: number) {
        let delMail = await api.delete(`mailers/delete/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setMailers(prevMailers => prevMailers.filter(mail => mail.id !== id));
            // setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });

        return delMail;
    }

    async function getMailers () {
        await api.get('mailers/list', {
            headers: { Authorization: user.token }
        }).then((resp: any) => {
            let datas = resp.data.sort(sort_by('unit_name', {name: 'name'}));
            setSearch('')
            setMailers(datas)
            setMailersList(datas)
            setReady(true)
        });
    }

    const handleNextPage = () => {
        if (currentPage < Math.ceil(mailers.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMailers = mailers.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        !ready && getMailers()
    }, [ready])

    return <Admin pageTitle="Gerenciamento de Emails - Clientes Energizou" title="Gerenciamento" subtitle="Controle de Envio de Emails" classMain='adm_mailer'>
        {
            ready ? <>
                <div className="buttons">
                    <button
                        type="button"
                        className="addMail"
                        onClick={() => openModal('addMail')}
                        data-for="tooltip_mailer_page"
                        data-tip="Gerenciar Envio"
                    >
                        <FaPlus size="16" />
                    </button>
                    <div className='searchBox'>
                        <input
                            name="search" id="search"
                            type="text" autoComplete='false'
                            placeholder='Nome da unidade, Grupo ou Estado...'
                            value={search} onChange={onChangeSearch}
                            disabled={ready ? false : true}
                        />

                        <div className={`btn${search !=='' ? ' lnk' : ''}`} onClick={() => {
                            if (search !=='') {
                                setMailers(mailersList)
                                setSearch('')
                            }
                        }}>
                            { search !=='' ? <FaTimes /> : <FaSearch /> }
                        </div>
                    </div>
                </div>

                <div className="logs">
                    {currentMailers.length ? currentMailers.map((el: any) => (
                        <MailBox
                            key={`log_${el.id}`}
                            edit={() => openModal('editMail', el)}
                            delete={() => deleteMail(el.id)}
                            {...el}
                        />
                    )) : <span className="alert">Não há emails cadastrados no momento</span>}
                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}><MdOutlineNavigateBefore /></button>
                        <span>Página {currentPage} de {Math.ceil(mailers.length / itemsPerPage)}</span>
                        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(mailers.length / itemsPerPage)}><MdOutlineNavigateNext /></button>
                    </div>
                </div>

                <ReactTooltip
                    id="tooltip_mailer_page"
                    place="top"
                    effect="solid"
                    type="info"
                />
            </> : <LoadingSingle />
            // </> : <h1>Carregando</h1>
        }
    </Admin>
}

export default AdminMailer;