import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts';
import { GroupsContext } from '~/contexts/forms/groups';

const Form: React.FC<any> = ({group, setReady}) => {
    const [groupData, setGroupData] = useState<any>(null);

    // Contexts
    const Groups = useContext(GroupsContext);
    const Modal  = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        const data = {[name]: (name==='active' ? Number(value) : value)};
        setGroupData({...groupData, ...data})
    }

    useEffect(() => {
        if (!groupData) setGroupData(group)
    }, [group, groupData])

    return (
        groupData && <>
            <form method="post" onSubmit={(event: React.FormEvent<HTMLFormElement>) => event.preventDefault()} className="editUser">
                <div className="form-group">
                    <div className="input-group">
                        <label htmlFor="name">Nome</label>
                        <input id="name" name="name" type="text" placeholder="Nome" value={groupData.name} onChange={onChange} />
                    </div>

                    <div className="input-group w140">
                        <label htmlFor="active">Status</label>
                        <select name="active" id="active" onChange={onChange} value={groupData.active===true || Number(groupData.active)===1 ? 1 : 0}>
                            <option value="1">Ativo</option>
                            <option value="0">Inativo</option>
                        </select>
                    </div>
                </div>
            </form>

            <footer>
                <button id="btnSave" type="button" onClick={() => Groups.save(groupData, Modal, setReady)}>Salvar</button>
                <button id="btnCancel" type="button" onClick={() => Modal.setModalOpen(false)}>Cancelar</button>
            </footer>
        </>
    );
}

export default Form;