import numbers from '~/utils/numbers';

const calculate = {
    numbers,

    consMWh: function (kwh: number, dec?: boolean) {
        let ret = kwh/1000;

        return kwh>0 ? ((dec && dec===true ? numbers.format(ret, dec, 3) : numbers.format(ret))) : '0,000';
    },

    consMWm: function (kwh: number, dec?: boolean) {
        let date = new Date(),
            mwh  = kwh/1000,
            mwm  = mwh/(this._getMonthsVector()[date.getMonth()]*24);

        return kwh>0 ? ((dec && dec===true ? numbers.format(mwm, dec, 3) : numbers.format(mwm))) : '0,000';
    },

    consFat: function (kwh: number, price: number, form?: boolean) {
        let consMwh = this.consMWh(kwh),
            ret     = parseInt(consMwh.replace('.', ''))*price;

        return kwh>0 ? (!form ? numbers.money(ret) : String(ret)) : (!form ? 'R$ ' : '') + '0,00';
    },

    prevConsMWh: function (cons: any, dec?: boolean) {
        let date    = new Date(),
            current = cons.current ? cons.current : cons.consumption,
            ret     = (current/1000)/(date.getDate()-(cons.missing/24))*this._getMonthsVector()[date.getMonth()];

        return current>0 ? ((dec && dec===true ? numbers.format(ret, dec, 3) : numbers.format(ret))) : '0,000';
    },

    prevConsMWm: function (cons: any, dec?: boolean) {
        let date    = new Date(),
            current = cons.current ? cons.current : cons.consumption,
            ret     = ((current/1000)/(date.getDate()-(cons.missing/24))*this._getMonthsVector()[date.getMonth()])/(this._getMonthsVector()[date.getMonth()]*24);

        return current>0 ? ((dec && dec===true ? numbers.format(ret, dec, 3) : numbers.format(ret))) : '0,000';
    },

    prevFat: function (cons: any, price: number, form?: boolean) {
        let prevMwh = this.prevConsMWh(cons),
            current = cons.current ? cons.current : cons.consumption,
            ret     = parseInt(prevMwh.replace('.', ''))*price;

        return current>0 ? (!form ? numbers.money(ret) : String(ret)) : (!form ? 'R$ ' : '') + '0,00';
    },

    monthFat: function (kwh: number, price: number) {
        let ret = (kwh/1000)*price;

        return numbers.money(ret);
    },

    unitsCons: function (data: any) {
        let mwh  = 0,
            mwm  = 0,
            fat  = 0,
            miss = 0,
            err  = 0,
            ret  = {
                units: data.length,
                mwh : '',
                mwm : '',
                fat : '',
                miss: 0,
                err : false
            };

        data.map((el: any) => {
            mwh  = mwh + parseFloat(this.consMWh(el.consumption, true).replace('.', '').replace(',', '.'));
            mwm  = mwm + parseFloat(this.consMWm(el.consumption, true).replace('.', '').replace(',', '.'));
            fat  = fat + parseFloat(this.consFat(el.consumption, el.contract, true));
            miss = el.missing ? el.missing/24 : 0;
            if (el.contract===0) err = 1;
            return true;
        });

        ret.mwh  = numbers.format(mwh, true);
        ret.mwm  = numbers.format(mwm, true);
        ret.fat  = fat>0 ? numbers.money(fat) : 'R$ 0,00';
        ret.miss = miss;
        ret.err  = err ? true : false;

        return ret;
    },

    unitsPrev: function (data: any) {
        let mwh = 0,
            mwm = 0,
            fat = 0,
            err = false,
            ret = {
                units: data.length,
                mwh: '',
                mwm: '',
                fat: '',
                err: false
            };

        data.map((el: any) => {
            mwh = mwh + parseFloat(this.prevConsMWh(el, true).replace('.', '').replace(',', '.'));
            mwm = mwm + parseFloat(this.prevConsMWm(el, true).replace('.', '').replace(',', '.'));
            fat = el.contract>0 ? fat + parseFloat(this.prevFat(el, el.contract, true)) : fat;
            if (el.contract===0) err = true;
            return true;
        });

        ret.mwh = numbers.format(mwh, true);
        ret.mwm = numbers.format(mwm, true);
        ret.fat = fat>0 ? numbers.money(fat) : 'R$ 0,00';
        ret.err = err;

        return ret;
    },

    _getMonthsVector: function () {
        let date = new Date(),
            year = date.getUTCFullYear();

        return (
            (year%400===0) || (year%4===0 && year%100!==0) ?
            [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31] :
            [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
        )
    }
}

export default calculate;