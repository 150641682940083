import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GeneralContext } from '~/contexts';
import { MixPanelContext } from '~/contexts/mixPanel';

const Logout: React.FC = () => {
    const navigate       = useNavigate()
    const {user, logout} = useContext(GeneralContext)
    const {loggedOut}    = useContext(MixPanelContext);

    useEffect(() => {
        // Logout
        logout()
        navigate('/login', {replace: true})

        loggedOut(user.id, user.role)
    })

    return null
}

export default Logout;