import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import './styles.css';

interface Props {
    name: string;
    mail: string;
    unit_name: string;
    economy_report: string | number;
    ccee_sales_off: string | number;
    ccee_backup_power: string | number;
    ccee_fees: string | number;
    edit: Function;
    delete: Function;
}

const Mails: React.FC<Props> = (props) => {
    return (
        <>
            <div className='mail_line'>
                <div className="header">
                    <div className="name">
                        <label><span>Nome</span></label>
                        <div><span>{props.name}</span></div>
                    </div>

                    <div className="mail">
                        <label><span>Email</span></label>
                        <div><span>{props.mail}</span></div>
                    </div>

                    <div className="unit">
                        <label><span>Unidade</span></label>
                        <div><span>{props.unit_name}</span></div>
                    </div>

                    <div className="report">
                        <label><span>Relatórios</span></label>
                        <span>{props.economy_report ? 'Sim' : 'Não'}</span>
                    </div>

                    <div className="sales_off">
                        <label><span>Garantia Financeira</span></label>
                        <span>{props.ccee_sales_off ? 'Sim' : 'Não'}</span>
                    </div>

                    <div className="backup_power">
                        <label><span>Energia de Reserva</span></label>
                        <span>{props.ccee_backup_power ? 'Sim' : 'Não'}</span>
                    </div>

                    <div className="ccee_fees">
                        <label><span>Taxa CCEE</span></label>
                        <span>{props.ccee_fees ? 'Sim' : 'Não'}</span>
                    </div>

                    <div className="buttons">
                        <button
                            type="button"
                            className="editMail"
                            onClick={() => props.edit()}
                            data-for="tooltip_mailer_line"
                            data-tip="Editar"
                        >
                            <FaPencilAlt size="14" />
                        </button>

                        <button
                            type="button"
                            className="delMail"
                            onClick={() => props.delete()}
                            data-for="tooltip_mailer_line"
                            data-tip="Excluir"
                        >
                            <FaTrash size="14" />
                        </button>
                    </div>
                </div>
            </div>

            <ReactTooltip
                id="tooltip_mailer_line"
                place="top"
                effect="solid"
                type="info"
            />
        </>
    )
}

export default Mails;