import React from 'react';
import { FaQuestionCircle }from 'react-icons/fa';

const infoColor = "#38A2FF";

const funcNotes: any = {
    data: null,
    mercadoLivre: null,
    number: 1,

    // Set Datas
    init: function (data: any, mercadoLivre: any) {
        this.number       = 1;
        this.data         = data;
        this.mercadoLivre = mercadoLivre;
    },

    // Treat Functions
    treatNotes: function (item: string, data: any) {
        switch (item) {
            case 'liq':
                return !data.liquid;

            case 'bkp':
                return !data.bkpPower;

            case 'ccee':
                return !data.ccee;

            case 'adds':
                return !data.liquid || !data.bkpPower || !data.ccee;

            case 'teACL':
                return !data.teACL;

            case 'perdaTrans':
                return !data.perdaTrans;

            case 'proinfa':
                return !data.proinfa;

            case 'infosCCEE':
                return !data.teACL || !data.perdaTrans || !data.proinfa;

            case 'pisCofins':
                return parseInt(data.pisCofins)===0;

            case 'powerhub':
                return data.powerhub===null;

            case 'diffICMS':
                return data.icmsDiff===1;

            default:
                return false;
        }
    },

    getInvoice: function () {
        return this.treatNotes('powerhub', this.data) ? <li>* - Fatura da distribuidora não foi localizada para o mês solicitado</li> : '';
    },

    getAdds: function () {
        if (this.treatNotes('adds', this.data)) {
            this.number = this.number+1;
            return <li>{this.getNumber(this.number)} - Dados referentes a Liquidação Financeira, Energia de Reserva e/ou Taxa da CCEE ainda não disponibilizados pela CCEE, os valores apresentados são previsões;</li>
        }

        return <></>
    },

    getInfosCCEE: function () {
        if (this.treatNotes('infosCCEE', this.mercadoLivre)) {
            this.number = this.number+1;
            return <li>{this.getNumber(this.number)} - Dados referentes a TE ACL, perdas de transmissão e/ou Energia Proinfa ainda não disponíveis no sistema;</li>
        }

        return <></>
    },

    getPisCofins: function () {
        if (!this.data.pisCofins) return '';
        this.number = this.number+1;
        if (this.treatNotes('pisCofins', this.data)) return <li>{this.getNumber(this.number)} - O cliente não possui alíquota de impostos de PIS/PASEP e COFINS por estar em área com isenção dos mesmos;</li>
        return <li>{this.getNumber(this.number)} - Para a alíquota dos impostos PIS/PASEP e COFINS é desconsiderada a variação mensal e utilizado o valor médio de {this.data.pisCofins}%, como a soma das alíquotas dos dois impostos;</li>
    },

    getDiffICMS: function () {
        if (this.treatNotes('diffICMS', this.data)) {
            return <li>** - Cliente com liminar de icms;</li>
        }

        return <></>
    },

    getLiqBkpCCEE: function (type: string) {
        let ret = false;

        if (type==='liq' && this.treatNotes('liq', this.data)) ret = true;
        else if (type==='bkp' && this.treatNotes('bkp', this.data)) ret = true;
        else if (type==='ccee' && this.treatNotes('ccee', this.data)) ret = true;

        if (ret) {
            let num = 4;
            if (!this.data.pisCofins && !this.treatNotes('infosCCEE', this.mercadoLivre)) num = 2;
            else if (this.data.pisCofins && !this.treatNotes('infosCCEE', this.mercadoLivre)) num = 3;

            return <>
                &nbsp;{this.getNumber(num)}
                <FaQuestionCircle
                    style={{marginLeft:"10px"}}
                    color={infoColor}
                    title="Dados referentes a Liquidação Financeira, Energia de Reserva e/ou Taxa da CCEE ainda não disponibilizados pela CCEE, os valores apresentados são previsões;"
                />
            </>
        }

        return <></>
    },

    getTLProInfa: function (type: string) {
        let ret = false;

        if (type==='teAcl' && this.treatNotes('teACL', this.mercadoLivre)) ret = true;
        else if (type==='perda' && this.treatNotes('perdaTrans', this.mercadoLivre)) ret = true;
        else if (type==='proinfa' && this.treatNotes('proinfa', this.data)) ret = true;

        if (ret) {
            let num = this.data.pisCofins ? 3 : 2;

            return <>
                &nbsp;{this.getNumber(num)}
                <FaQuestionCircle
                    style={{marginLeft:"10px"}}
                    color={infoColor}
                    title="Dados referentes a TE ACL, perdas de transmissão e/ou Energia Proinfa ainda não disponíveis no sistema;"
                />
            </>
        }

        return <></>
    },

    getBasePisCofins: function () {
        return <>
            &nbsp;{this.getNumber(1)}
            <FaQuestionCircle
                style={{marginLeft:"10px"}}
                color={infoColor}
                title="Conforme decisão proferida pelo STJ o PIS/COFINS não possui mais o ICMS na sua base de calculo"
            />
        </>
    },
    getOthersValues: function (text:any) {
        return <>
            &nbsp;*
            <FaQuestionCircle
                style={{marginLeft:"10px"}}
                color={infoColor}
                title={text}
            />
        </>
    },

    getPCofins: function () {

        if (!this.data.pisCofins) return <></>

        if (this.treatNotes('pisCofins', this.data)) {
            return <>
                &nbsp;{this.getNumber(2)}
                <FaQuestionCircle
                    style={{marginLeft:"10px"}}
                    color={infoColor}
                    title="O cliente não possui alíquota de impostos de PIS/PASEP e COFINS por estar em área com isenção dos mesmos;"
                />
            </>
        }

        return <>
                &nbsp;{this.getNumber(2)}
                <FaQuestionCircle
                    style={{marginLeft:"10px"}}
                    color={infoColor}
                    title={`Para a alíquota dos impostos PIS/PASEP e COFINS é desconsiderada a variação mensal e utilizado o valor médio de ${this.data.pisCofins}%, como a soma das alíquotas dos dois impostos;`}
                />
            </>
    },

    getDICMS: function () {
        if (this.treatNotes('diffICMS', this.data)) {
            return <>
                &nbsp;**
                <FaQuestionCircle
                    style={{marginLeft:"10px"}}
                    color={infoColor}
                    title="Cliente com liminar de icms;"
                />
            </>
        }

        return <></>
    },

    getNumber: function (i: number) {
        if (i===1) return '¹';
        else if (i===2) return '²';
        else if (i===3) return '³';
        else if (i===4) return '⁴';
        else if (i===5) return '⁵';
        else if (i===6) return '⁶';
        else if (i===7) return '⁷';
        else if (i===8) return '⁸';
        else if (i===9) return '⁹';
        return '';
    },
}

export default funcNotes;