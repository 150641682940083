import { useContext, createContext, ReactNode } from "react";
import api from "~/services/api";

// Interfaces
import { GroupState } from './interfaces'

// Contexts
import { GeneralContext } from '~/contexts';

interface GroupsContextData {
    save  : Function;
    remove: Function;
}

interface GroupsProviderProps {
    children: ReactNode;
}

export const GroupsContext = createContext({} as GroupsContextData)

export function GroupsProvider ({ children }: GroupsProviderProps) {
    const {user} = useContext(GeneralContext);

    async function save (groupData: GroupState, Modal: any, setReady: Function) {
        api.post(`users/groups`, groupData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    async function remove (id: number, Modal: any, setReady: Function) {
        api.delete(`users/groups/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    return (
        <GroupsContext.Provider value={{save, remove}}>
            { children }
        </GroupsContext.Provider>
    )
}