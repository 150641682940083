import { useContext, createContext, ReactNode } from "react";
import api from "~/services/api";

// Interfaces
import {
    UnitsState, EnergiesState,
    PricesState, OthersState,
    NotesState, IcmsState,
    LostEnergyState, TeAclState
} from './interfaces'

// Contexts
import { GeneralContext } from '~/contexts';

interface UnitsContextData {
    save                : Function;
    remove              : Function;
    saveGlobalLostEnergy: Function;
    saveEnergies        : Function;
    savePrices          : Function;
    saveOthers          : Function;
    saveNotes           : Function;
    saveIcms            : Function;
    saveLostEnergy      : Function;
    saveTeAcl           : Function;
}

interface UnitsProviderProps {
    children: ReactNode;
}

export const UnitsContext = createContext({} as UnitsContextData)

export function UnitsProvider ({ children }: UnitsProviderProps) {
    const {user} = useContext(GeneralContext);

    // Unit Functions
    async function save (unitData: UnitsState, Modal: any, setReady: Function) {
        api.post('clients', unitData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    async function remove (id: number, Modal: any, setReady: Function) {
        api.delete(`clients/${id}`, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    async function saveGlobalLostEnergy (lostEnergy: any, setLostEnergy: Function, setLostEnergyEdit: Function) {
        setLostEnergyEdit(true);
        let data = { ...lostEnergy, value: (lostEnergy.value ? parseFloat(lostEnergy.value) : 0)};

        api.post('clients/lost_energy', data, {
            headers: { Authorization: user.token }
        }).then(resp => {
            setLostEnergy(resp.data);
            setTimeout(() => setLostEnergyEdit(false), 1000);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
    }

    // Others Functions
    async function saveEnergies (id: number, data: EnergiesState[], Modal: any, setReady: Function) {
        const energiesData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
            api.post('clients/energies', energiesData, {
                headers: { Authorization: user.token }
            }).then(resp => {
                Modal.setModalOpen(false);
                setReady(false);
            }).catch(err => {
                const { status } = err.response;

                if (status===400) {
                    const error = err.response.data.errors;
                    console.log(error);
                }
            });
        }
    }

    async function savePrices (id: number, data: PricesState[], Modal: any, setReady: Function) {
        const pricesData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/prices', pricesData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    async function saveOthers (id: number, data: OthersState[], Modal: any, setReady: Function) {
        const othersData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/others', othersData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    async function saveNotes (id: number, data: NotesState[], Modal: any, setReady: Function) {
        const notesData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/others_notes', notesData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    async function saveIcms (id: number, data: IcmsState[], Modal: any, setReady: Function) {
        const icmsData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/icms', icmsData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    async function saveLostEnergy (id: number, data: LostEnergyState[], Modal: any, setReady: Function) {
        const lostEnergyData = data.length>0 ? data : { id };

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/lost_energy', lostEnergyData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    async function saveTeAcl (id: number, data: TeAclState[], Modal: any, setReady: Function) {
        const teAclData = data.length>0 ? data : { id };
        console.log("🚀 ~ saveTeAcl ~ teAclData:", teAclData)

        let toCheckValueDate = verifyDuplicatesDate(data)

        if(toCheckValueDate.length > 0){
            Modal.setModalMessage(toCheckValueDate[0])
            setTimeout(()=> Modal.setModalMessage('')
            , 2000);
        }else{
        api.post('clients/te', teAclData, {
            headers: { Authorization: user.token }
        }).then(resp => {
            Modal.setModalOpen(false);
            setReady(false);
        }).catch(err => {
            const { status } = err.response;

            if (status===400) {
                const error = err.response.data.errors;
                console.log(error);
            }
        });
      }
    }

    function verifyDuplicatesDate(dados: any) {
        let anos: number[] = [];
        let anosMeses: string[] = [];
        let erros: string[] = [];

        dados.forEach((item: any) => {
            let { year, month } = item;

            if (typeof year === 'string') {
                year = parseInt(year);
            }

            if (month === 0) {
                if (anos.indexOf(year) !== -1) {
                    erros.push(`Dados duplicado encontrado: Ano: ${year}, Mês: Geral`);
                } else {
                    anos.push(year);
                }
            } else {
                let anoMes = `${year}-${month}`;
                if (anosMeses.indexOf(anoMes) !== -1) {
                    erros.push(`Dados duplicado encontrado: Ano: ${year}, Mês: ${month}`);
                } else {
                    anosMeses.push(anoMes);
                }
            }
        });
        return erros;
    }

    return (
        <UnitsContext.Provider value={{
            save, remove,
            saveGlobalLostEnergy,
            saveEnergies, savePrices,
            saveOthers, saveNotes,
            saveIcms, saveLostEnergy, saveTeAcl
        }}>
            { children }
        </UnitsContext.Provider>
    )
}