import React from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import GroupsInt from '~/interfaces/Groups';
import './styles.css';

interface CompProps {
    edit: Function;
    delete: Function;
}

type Props = GroupsInt & CompProps;

const Group: React.FC<Props> = (props) => {
    return (
        <div className="groupBox">
            <div className="groupData">
                <h3>{ props.name }</h3>
                <div className="units">
                    <span>{ props.units.length }</span>
                    <small>Unidades</small>
                </div>
            </div>

            <div className="buttons">
                <button type="button" className="edit" onClick={() => props.edit ? props.edit() : ''} title="Editar Grupo">
                    <FiEdit2 size={14} /><span> Editar Grupo</span>
                </button>

                <button type="button" className="delete" onClick={() => props.delete ? props.delete() : ''} title="Excluir Grupo">
                    <FiTrash2 size={14} /><span> Excluir</span>
                </button>
            </div>
        </div>
    );
}

export default Group;