import formatCurrency from './format';
import decimals from '../constants/decimals';

const clearNumber = (number: any) => {
    if (typeof number === 'number') return number;

    // strips everything that is not a number (positive or negative)
    return Number(number.toString().replace(/[^0-9-]/g, ''));
};

const normalizeValue = (number: string | number, digits?: number): number => {
    let maxDigits = digits ? digits : decimals.maximum_digits;
    let safeNumber: any = number;
    if (typeof number === 'string') {
        safeNumber = clearNumber(number);
        if (safeNumber % 1 !== 0) {
            safeNumber = safeNumber.toFixed(maxDigits);
        }
    } else {
        // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
        safeNumber = Number.isInteger(number)
            ? Number(number) * 10 ** maxDigits
            : number.toFixed(maxDigits);
    }
    // divide it by 10 power the maximum fraction digits.
    return clearNumber(safeNumber) / 10 ** maxDigits;
};

const maskValues = (locale: string, inputFieldValue: string | number | undefined, currency: string, shouldCutSymbol: boolean, digits?: number): {value: number, maskedValue: string} => {
    if (!inputFieldValue) return {value: 0, maskedValue: ''};
    const value = normalizeValue(inputFieldValue, digits);
    const maskedValue = formatCurrency(locale, value, currency, shouldCutSymbol, digits);
    return {value, maskedValue: (value < 0 ? '-' : '') + maskedValue.trim()};
};

export {
    maskValues,
    normalizeValue,
    clearNumber
}
