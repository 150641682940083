import Cookie from 'js-cookie';

var storage:any = {};

// Safari in incognito has local storage, but size 0
// This system falls back to cookies in that situation
try {
    if (!window.localStorage) {
        throw Error('no local storage');
    }

    // Setup simple local storage wrapper
    storage.set = (key: any, value: any, obj?: boolean) => {
        value = (obj ? JSON.stringify(value) : value);
        return localStorage.setItem(key, value);
    }

    storage.get = (key: any, obj?: boolean) => {
        const item = localStorage.getItem(key) || '';

        try {
            return (obj ? JSON.parse(item) : item);
        } catch (e) {
            return null;
        }
    };

    storage.remove = (key: any) => localStorage.removeItem(key);
} catch (e) {
    storage.set    = Cookie.set;
    storage.get    = Cookie.get;
    storage.remove = Cookie.remove;
}

export default storage;