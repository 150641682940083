import { useContext, useEffect, useState } from "react";

import { GeneralContext, MixPanelContext, ModalContext } from "~/contexts";
import api from "~/services/api";
import dates from "~/utils/dates";
import FormBill from '~/pages/Bills/Form';
import icon from './images/icon.svg';
import Button from '~/components/Parts/Forms/Button';
import { RiLoader2Line } from "react-icons/ri";
import { FaDownload, FaPlus, FaTrash } from "react-icons/fa";

const BillHistoryBox: React.FC<any> = (props: any) => {
    const { user } = useContext(GeneralContext);
    const Modal = useContext(ModalContext);

    const [downReady, setDownReady] = useState<Boolean>(true);

    const dateFormat = dates._getDateByDate(props.Bill.reference_date);
    const dateFormatCreate = dates._getDateByDate(props.Bill.created_at);


    function downloadPDF(path: string) {
        setDownReady(false);

        api.get(`bill/${path}`, {
            headers: { Authorization: user.token },
            responseType: 'blob'
        }).then(resp => {
            let url = window.URL.createObjectURL(new Blob([resp.data]));
            let link = document.createElement('a');
            let fileName = `${props.companyName ? props.companyName : 'Sem Nome'} - ${dateFormat.monthName} de ${dateFormat.year}.pdf`;

            setTimeout(() => {
                link.href = url;
                link.setAttribute("id", "download");
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
                setDownReady(true);
            }, 1000);
        })
    }

    return <div className='billBox'>
        <div className="icon">
            <img src={icon} alt="Icon" />
        </div>

        <div className="blank"></div>

        <div className="client">
            <span><strong>Unidade:</strong></span>
            <span>{props.companyName}</span>
        </div>

        <div className="provider">
            <span><strong>Distribuidora:</strong></span>
            <span>{props.Bill.status == "Read" ? props.Bill.provider : "Aguardando"}</span>
        </div>

        <div className="status">
            <span><strong>Status:</strong></span>
            {props.Bill.status === "Read" ?
                <span className="status-completed">Concluído</span>
                : <span className="status-pending">Aguardando</span>}
        </div>

        <div className="dateInfo">
            <span><strong>Mês de referência:</strong></span>
            <span> {dateFormat.monthName} </span>
        </div>

        <div className="dateInfo">
            <span><strong>Data de envio:</strong></span>
            <span>{dateFormatCreate.day} de {dateFormatCreate.monthName} de {dateFormatCreate.year}</span>
        </div>

        <div className="buttons">
            <Button
                type="button"
                className={`down${!downReady || props.path ? ' load' : ''}`}
                icon={!downReady ? <RiLoader2Line size={18} /> : <FaDownload />}
                text={null}
                func={() => downloadPDF(props.path)}
                disabled={!downReady || props.path === null ? true : false}
            />
        </div>
    </div>;
}

export default BillHistoryBox;