import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { FaSave } from 'react-icons/fa';
import { FiEdit2, FiSave, FiTrash2, FiX } from 'react-icons/fi';
import Numbers from '~/utils/numbers';
import Dates from '~/utils/dates';
import sort_by from '~/utils/sort';
import funcs from '~/utils/funcs';
import './styles.css';

// Contexts
import { ModalContext } from '~/contexts';
import { UnitsContext } from '~/contexts/forms/units';

// Components
import CurrencyInput from '~/components/Modules/CurrencyMask';

const Form: React.FC<any> = ({unit, teAcl, setReady}) => {
    const [teAclData, setTeAclData] = useState<any>(null);
    const [teAclV, setTeAclV]       = useState<any>();
    const [teAclPO, setTeAclPO]     = useState<any>();

    // Contexts
    const Units = useContext(UnitsContext);
    const Modal = useContext(ModalContext);

    function onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const {name, value} = event.target;
        let newData = JSON.parse(JSON.stringify(teAclV))
        let data    = funcs.onChange(newData, name, value);
        setTeAclV(data);
    }

    function onChangeFloat(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>, originalValue: any) {
        let {name}  = event.target;
        let newData = JSON.parse(JSON.stringify(teAclV))
        let data    = funcs.onChangeFloat(newData, name, originalValue);
        setTeAclV(data);
    }

    function formatValueTe (value: string | number) {
        if (typeof value==='undefined') return '';
        else if (typeof value==='string') return Numbers.money(parseFloat(value.replace('.', '').replace(',', '.')), false, true);
        return Numbers.money(value, false, true);
    }

    function getMonths () {
        let ret: any = [];

        for (let i=0; i<=11; i++) {
            let item = {
                month: i+1,
                name: Dates._getNameMonth(i)
            };

            ret.push(item);
        }

        return ret;
    }

    function getYears () {
        let init = Number(Dates.getYear()),
            ret: any = [];

        for (let i=init; i>=(init-20); i--) {
            ret.push(i);
        }

        return ret;
    }

    function getMonthName(month: number) {
        month = Number(month);
        if (month===0) return 'Geral';
        return Dates._getNameMonth((month-1));
    }

    function addTe(index: number) {
        let date  = new Date();
        let data  = {unit_id: unit, year: date.getFullYear(), month: 0, price: 0, index};
        let datas = [...teAclData]

        // Add Item
        datas.push(data);
        //datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setTeAclData(datas);
    }

    function editTe(elem: any, index: number) {
        let item = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.add('active');

        setTeAclV(elem);
        setTeAclPO(elem);
    }

    function deleteTe(elem: any) {
        let datas = JSON.parse(JSON.stringify(teAclData));
        let idx = datas.findIndex((q: any) => q.name===elem.name && q.mail===elem.mail && q.unit_id===elem.unit_id);

        // Remove Item
        datas.splice(idx, 1);
        datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setTeAclData(datas);
    }

    function saveTe(elem: any, index: number) {
        let datas = JSON.parse(JSON.stringify(teAclData));
        let item  = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.remove('active');

        // Change Item
        let idx = datas.findIndex((q: any) => q.year===teAclPO.year && q.month===teAclPO.month && q.unit_id===teAclPO.unit_id && q.index===index);
        datas.splice(idx, 1, teAclV);
        //datas.sort(sort_by({name: 'year', reverse: true}, {name: 'month'}));

        // Set new items
        setTeAclData(datas);

        // Clear Data Items
        setTeAclV(null);
        setTeAclPO(null);
    }

    function cancelTe(elem: any, index: number) {
        let item = document.querySelector(`div[data-rel="${elem.unit_id}_${elem.year}_${elem.month}_${index}"]`);
        item?.classList.remove('active');
        setTeAclV(null);
    }

    async function getData(){
        let ret: any = []
        console.log("🚀 ~ getData ~ teAcl:", teAcl)
        let teAclDB = teAcl.length>0 ? teAcl.sort(sort_by({name: 'year', reverse: true}, {name: 'month'})) : []

        await teAclDB.map((el:any, index: number) => ret.push({...el, index}))
        setTeAclData(ret)
    }

    useEffect(() => {
        if (!teAclData) getData()
    }, [teAcl, teAclData])

    return (
        teAclData && <>
        {
            teAclData.length>0 ? (
                <div className="header">
                    <div className="infos">
                        <div className="year">Ano</div>
                        <div className="month">Mês</div>
                        <div className="price">Montante</div>
                    </div>

                    <div className="buttons"></div>
                </div>
            ) : <></>
        }

        {
            teAclData.map((el: any, index: number) => (
                <div className="line" key={`${el.unit_id}_${el.year}_${el.month}_${teAclData.length - index - 1}`} data-rel={`${el.unit_id}_${el.year}_${el.month}_${index}`}>
                    <div className="infos">
                        <div className="item">
                            <div className="year">{ el.year }</div>
                            <div className="month">{ getMonthName(el.month) }</div>
                            <div className="price">KWH { formatValueTe(el.price ? el.price : '0.00') }</div>
                        </div>

                        <div className="edit">
                            <div className="year">
                                <select name="year" id="year" onChange={onChange}
                                defaultValue={el.year ? el.year : '0'}>
                                    {
                                        getYears().map((ye: any) => (
                                            <option key={`key_${ye}`} value={ye}>{ye}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="month">
                                <select
                                    name="month" id="month" onChange={onChange}
                                    defaultValue={el.month ? el.month : '0'}
                                >
                                    {/* <option value="0">Geral</option> */}

                                    <optgroup label="Meses">
                                        {
                                            getMonths().map((mo: any) => (
                                                <option key={`month_${mo.name}`} value={mo.month}>{mo.name}</option>
                                            ))
                                        }
                                    </optgroup>
                                </select>
                            </div>

                            <div className="price">
                                <CurrencyInput
                                    id="price" name="price" placeholder="0,00"
                                    onChangeValue={onChangeFloat}
                                    value={teAclV?.price}
                                    hideSymbol
                                />
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <div className="item">
                            <button className="edit" onClick={() => editTe(el, index)} disabled={(!teAclV ? false : true)}>
                                <FiEdit2 size={16} />
                            </button>

                            <button className="del" onClick={() => deleteTe(el)} disabled={(!teAclV ? false : true)}>
                                <FiTrash2 size={16} />
                            </button>
                        </div>

                        <div className="edit">
                            <button className="save" onClick={() => saveTe(el, index)}>
                                <FiSave size={16} />
                            </button>

                            <button className="cancel" onClick={() => cancelTe(el, index)}>
                                <FiX size={16} />
                            </button>
                        </div>
                    </div>
                </div>
            ))
        }

        <div className="footerBottons">
            <button className="btn add" onClick={() => addTe(teAclData.length)}>Adicionar novo preço</button>
            <button className="btn save" onClick={() => Units.saveTeAcl(unit, teAclData, Modal, setReady)}><FaSave /></button>
        </div>
    </>
    )
}

export default Form;