import { useEffect, useState } from 'react';
import {maskValues, normalizeValue} from './functions/helpers';
import {ICurrencyMaskProps} from './types/CurrencyMask';

const CurrencyInput: React.FC<ICurrencyMaskProps> = (({ InputElement, id, className, name, value, placeholder, defaultValue, digits, hideSymbol = false, disabled = false, currency = 'BRL', locale = 'pt-BR', max, autoSelect, autoReset, onChangeValue, onBlur, onFocus, onKeyPress, ...otherProps }) => {
    const [inputValue, setInputValue]: any = useState({value: 0, maskedValue: ''});

    const updateValues = (originalValue: any): {originalValue: number, maskedValue: string} => {
        const {value, maskedValue}: any = maskValues(locale, originalValue, currency, hideSymbol, digits);
        if (!max || value <= max) {
            setInputValue({value, maskedValue});
            return {originalValue: value, maskedValue: maskedValue};
        }

        return {originalValue: normalizeValue(inputValue), maskedValue: inputValue};
    };

    const handleChange = (event: any) => {
        event.preventDefault();
        const {originalValue, maskedValue} = updateValues(event.target.value);
        if (maskedValue) onChangeValue(event, originalValue, maskedValue);
    };

    const handleBlur = (event: any) => {
        const {originalValue, maskedValue} = updateValues(event.target.value);
        if (autoReset) maskValues(locale, 0, currency, hideSymbol, digits);
        if (maskedValue && onBlur) onBlur(event, originalValue, maskedValue);
    };

    const handleFocus = (event: any) => {
        if (autoSelect) event.target.select();

        const {originalValue, maskedValue} = updateValues(event.target.value);
        if (maskedValue && onFocus) onFocus(event, originalValue, maskedValue);
    };

    const handleKeyUp = (event: any) => onKeyPress && onKeyPress(event, event.key, event.key);

    useEffect(() => {
        const currentValue = value || defaultValue || undefined;
        const maskedValue = maskValues(locale, currentValue, currency, hideSymbol, digits);
        setInputValue(maskedValue);
    }, [currency, defaultValue, hideSymbol, value]);

    if (!InputElement) return <input
        {...otherProps}
        id={id}
        className={className}
        name={name}
        placeholder={placeholder}
        value={inputValue.maskedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
        disabled={disabled}
    />

    return <input
        {...otherProps}
        value={inputValue.maskedValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
    />
});

export default CurrencyInput;