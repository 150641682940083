import React, { ReactNode } from 'react';
import { createContext } from 'react';
import { FaTimes } from 'react-icons/fa';
import './styles.css';

// Components
import { Button } from '~/components/Parts/Forms';

interface ModalContextData {
    modalOpen       : boolean;
    setModalOpen    : Function;
    setModalClass   : Function;
    setModalStyle   : Function;
    setModalTitle   : Function;
    setModalBody    : Function;
    setModalMessage : Function;
    setModalButton  : Function;
}

interface ModalProviderProps {
    children: ReactNode;
}

export const ModalContext = createContext({} as ModalContextData)

export function ModalProvider ({children}: ModalProviderProps) {
    const [modalOpen, setModalState]      = React.useState(false);
    const [modalClass, setModalClass]     = React.useState('' || null);
    const [modalStyle, setModalStyle]     = React.useState({});
    const [modalTitle, setModalTitle]     = React.useState('');
    const [modalBody, setModalBody]       = React.useState(<></>);
    const [modalMessage, setModalMessage] = React.useState('');
    const [modalButton, setModalButton]   = React.useState(true);

    function setModalOpen (state: boolean) {
        setModalState(state);

        if (!state) {
            setModalClass(null);
            setModalStyle({});
            setModalTitle('');
            setModalBody(<></>);
            setModalMessage('');
        }
    }

    return (
        <ModalContext.Provider value={{modalOpen, setModalOpen, setModalClass, setModalStyle, setModalTitle, setModalBody, setModalButton, setModalMessage }}>
            {children}

            {
                modalOpen && <div id="modalBox" className={`${modalClass ? modalClass : ''}`}>
                    <div className="modalBody" style={modalStyle}>
                        <header>
                            {modalTitle ? <h3>{modalTitle}</h3> : <span></span>}
                            {
                                modalMessage ? <p>{modalMessage}</p> : modalButton ? <Button
                                type="button"
                                className="close"
                                func={() => setModalOpen(false)}
                                icon={<FaTimes size={20} />}
                                />: <></>
                            }
                        </header>

                        <div className="modalContent">
                            {modalBody}
                        </div>
                    </div>
                </div>
            }
        </ModalContext.Provider>
    );
}