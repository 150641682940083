import React from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import UsersInt from '~/interfaces/Users';
import './styles.css';

// Images
import user_image from '~/assets/images/user.jpg';

interface CompProps {
    edit: Function;
    delete: Function;
    token: string;
}

type Props = UsersInt & CompProps;

const Users: React.FC<Props> = (props) => {
    function getUserType (type: string) {
        switch (type) {
            case 'admin':
                return 'Administrador';

            case 'client':
                return 'Cliente';

            default:
                return 'Usuário';
        }
    }

    return (
        <div className="userBox">
            <div className="userData">
                <img src={props.image_url ? props.image_url : user_image} alt={`Imagem do usuário ${props.name}`} />

                <div className="infos">
                    <h3>{ props.name }</h3>
                    <div className="type">{ getUserType(props.type) }</div>
                </div>
            </div>

            <div className="buttons">
                <button type="button" className="editUser" onClick={() => props.edit ? props.edit() : ''} title="Editar Usuário">
                    <FiEdit2 size={20} /><span> Editar</span>
                </button>

                <button type="button" className="deleteUser" onClick={() => props.delete ? props.delete() : ''} title="Excluir Usuário">
                    <FiTrash2 size={20} /><span> Excluir</span>
                </button>
            </div>
        </div>
    );
}

export default Users;