import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import env from '~/utils/env';
import './styles.css';

// Context
import { GeneralContext } from '~/contexts';

// Import Components
import Header from '~/components/Default/Header';
import Menu from '~/components/Default/Menu';
import { MixPanelContext } from '~/contexts/mixPanel';

interface TitleProps {
    pageTitle: string;
    title: string;
    subtitle?: string;
    className?: string;
    classMain?: string;
    children: any;
    units?:any;
    economy?:any;
}

const Admin: React.FC<TitleProps> = (props) => {
    const {user, logged}    = useContext(GeneralContext);
    const {pageView}        = useContext(MixPanelContext);

    const [ready, setReady] = useState(false);
    const navigate          = useNavigate();

    useEffect(() => {
        if (!logged || !user.token) navigate('/login', {replace: true})
        setReady(true)

        // MixPanel Event
        if (props.pageTitle) pageView(user.id, props.pageTitle)
    }, []);

    return (
        <>
            <Helmet>
                <title>{props.pageTitle}</title>
                <link rel="canonical" href={env.host} />
            </Helmet>

            <Header title={props.title} subtitle={props.subtitle} units={props.units} />

            <section className={`container${props.className ? ' ' + props.className : ''}`}>
                <Menu />

                <main className={props.classMain ? props.classMain : ''}>
                    { ready ? props.children : <></> }
                </main>
            </section>
        </>
    );
}

export default Admin;