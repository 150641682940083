import React, { useContext, useState } from 'react';
import { FiDownload, FiLoader, FiMinus, FiPlus } from 'react-icons/fi';
import { FaFile, FaFileAlt, FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFolder } from 'react-icons/fa';
import DocsInt from '~/interfaces/Docs';
import api from '~/services/api';
import cryptos from '~/utils/cryptos';
import DocBox from './';
import './styles.css';
import { GeneralContext } from '~/contexts';

interface TokenProps {
    token      : string;
    doc_ref    : string;
    status    ?: string;
    folderDown?: string;
}

type Props = DocsInt & TokenProps;

const Documents: React.FC<Props> = (props) => {
    const [ready, setReady] = useState<Boolean>(true);
    const { unit, user }   = useContext(GeneralContext);
    const unitFolder = cryptos.encryptServer(unit.id + '-' + unit.folder);

    function _testDoc(type: string) {
        switch (type) {
            case 'folder':
                return <FaFolder size="20" color="#666666" />;

            case 'pdf':
                return <FaFilePdf size="20" color="#d3483e" />;

            case 'doc':
            case 'docx':
                return <FaFileWord size="20" color="#2a5699" />;

            case 'xls':
            case 'xlsx':
                return <FaFileExcel size="20" color="#207245" />;

            case 'ppt':
            case 'pptx':
                return <FaFilePowerpoint size="20" color="#d04727" />;

            case 'txt':
                return <FaFileAlt size="20" color="#999999" />;

            default:
                return <FaFile size="20" color="#3333333" />;
        }
    }

    function _openFolder() {
        setReady(!ready);
    }

    function _downloadFile(e: React.MouseEvent, refType: string, file: string, type: string, doc_ref: string) {
        e.preventDefault();
        setReady(false);
        let filePath = (props.folderDown ? props.folderDown + '|' + file : file);
        const urlDown = `/documents/download/${filePath}/${type}${refType==='user' ? '/user/' + unitFolder : ''}?ref=${doc_ref}`;

        api({
            url: urlDown,
            headers: { Authorization: props.token },
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            let url   = window.URL.createObjectURL(new Blob([response.data]));
            let link  = document.createElement('a');

            link.href = url;
            link.setAttribute("id", "download");
            link.setAttribute('download', file + '.' + type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            setReady(true);
        }).catch(err => {
            console.log(err);
        }) ;
    }

    return (
        !props.folder ? (
            <div className={`docBox${props.status ? ' ' + props.status : ''}`}>
                <div className="status"></div>

                <div className="icon">
                    { _testDoc(props.type) }
                </div>

                <div className="name">
                    { props.name }
                </div>

                <div className="size">
                    { props.size }
                </div>

                {
                    ready ?
                    <a href={(props.folderDown ? props.folderDown + '|' : '') + props.name + '/' + props.type} className="download" onClick={(e) => _downloadFile(e, props.refs, props.name, props.type, props.doc_ref)}>
                        <FiDownload size="18" color="#FFF" strokeWidth="3" />
                    </a> :
                    <button type="button" className="load">
                        <FiLoader size="18" color="#FFF" strokeWidth="3" />
                    </button>
                }
            </div>
        ) : (
            <>
                <div className={`docBox folder${props.folder && !ready ? ' active' : ''}`}>
                    <div className="status"></div>

                    <div className="icon">
                        { _testDoc('folder') }
                    </div>

                    <div className="name">
                        { props.name }
                    </div>

                    {
                        ready ?
                        <button type="button" className="open" onClick={() => _openFolder()}>
                            <FiPlus size="18" color="#FFF" strokeWidth="3" />
                        </button> :
                        <button type="button" className="close" onClick={() => _openFolder()}>
                            <FiMinus size="18" color="#FFF" strokeWidth="3" />
                        </button>
                    }
                </div>

                {
                    !ready && props.files && props.files.length>0 ? (
                        <>
                            {
                                props.files.map((el: any) => (
                                    <DocBox key={el.id} {...el} token={props.token} status="folder" folderDown={props.name} doc_ref={props.doc_ref}/>
                                ))
                            }
                            <hr />
                        </>
                    ) : <></>
                }
            </>
        )
    );
}

export default Documents;