import React, { useEffect, useState } from 'react';
import mobile from 'is-mobile';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import dates from '~/utils/dates';
import numbers from '~/utils/numbers';
import Funcs from '../funcs';
import './styles.css';
import { useParams } from 'react-router-dom';

const TotalValue:React.FC<any> = ({className, styles, months, infos}: any) => {
	let params         			  = useParams();
	let yearMonth: any 			  = params.yearMonth;
	const [data, setData]         = useState<any>(null);
	const [interval, setInterval] = useState(0);

	// Chart Funcions
	const renderCustomTooltip = ({ active, payload }: any) => {
		let total: any = null;
		payload.map((el: any) => total = el)

		// Treat Title
		let dateBase = total ? total.payload.date.split('-') : null;
		let perc = total?.payload?.perc || 0
		let title    = total ? dates._getNameMonth(dateBase[1], true) + '/' + dateBase[0] : '';
		return <div className='tooltipChart'>
			<h4>{title}</h4>

			{
				total && (total.value>0 || total.value<0) ? <div style={{color: total ? total.color : '#A0A0A0'}}>
					<span>Valor: {numbers.money(total.value, true, true)}</span><br/>
					<span>Percentual: {numbers.money(perc, true, false)}%</span>
				</div> : <div style={{color: '#FFAFAF'}}>
					<span>Não Calculado</span>
				</div>
			}
		</div>
	}

	const renderCustomLegend = ({ active, payload }: any) => {
		let total: any = null;
		payload.map((el: any) => total = el)

		return <>
			<div style={{color: '#666666'}}>
				<span className="color" style={{backgroundColor: total ? total.color : '#A0A0A0'}}></span>
				Economia em R$
			</div>
		</>
	}

	const getDefaultInfos = () => {
		let yearMonths    = dates.getMonthsLastYear(yearMonth);
		let quarter: any  = {data: [], total: 0}
		let semester: any = {data: [], total: 0}
		let yearly: any   = {data: [], total: 0}

		yearMonths.map((el: any, idx: number) => {
			if (idx<3) quarter.data.push({date: el.year + '-' + el.month, year: 2023, month: el.year, perc: 0, total: 0})
			if (idx<6) semester.data.push({date: el.year + '-' + el.month, year: 2023, month: el.year, perc: 0, total: 0})
			yearly.data.push({date: el.year + '-' + el.month, year: 2023, month: el.year, perc: 0, total: 0})
		})

		return {
			quarter,
			semester,
			yearly
		}
	}

	// Mount Functions
	useEffect(() => {
		let infosData = getDefaultInfos();
		if (infos!==undefined && infos!==null) {
			infosData = infos;
		}

		switch (months) {
			case 12:
				let dataYearly = JSON.parse(JSON.stringify(infosData.yearly.data)).reverse();
				setData(dataYearly)
				setInterval(2)
				break
			case 6:
				let dataSemester = JSON.parse(JSON.stringify(infosData.semester.data)).reverse();
				setData(dataSemester)
				setInterval(1)
				break
			case 3:
				let dataQuarter = JSON.parse(JSON.stringify(infosData.quarter.data)).reverse();
				setData(dataQuarter)
				setInterval(0)
				break
		}
    }, [months, infos]);

	return <div className={`${className ? className : ''} economyChart`} style={styles}>
		<header>
			<div className="titles">
				<h3>Economia obtida até o momento em R$</h3>
				<p>Veja a economia obtida neste ano até o mês atual</p>
			</div>
		</header>

		{
			data ? <section className="content">
				<ResponsiveContainer height={mobile() ? 250 : 350}>
					<BarChart
						data={data}
						margin={{
							top: 20,
							right: 30,
							left: 20,
							bottom: 5,
						}}
					>
						<CartesianGrid vertical={false}/>
						<XAxis dataKey="date" interval={interval} tick={Funcs.renderXAxisLabel} />
						<YAxis tick={(props: any) => Funcs.renderYAxisLabelCurr(props, true, false)}/>
						<Tooltip content={renderCustomTooltip} />
						<Legend content={renderCustomLegend} />

						<Bar dataKey="total" stackId="a" fill="#AFBAC9" radius={[5,5,0,0]}/>
					</BarChart>
				</ResponsiveContainer>
			</section> : <></>
		}
	</div>;
}

export default TotalValue;