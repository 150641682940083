import React from 'react';
import ReactTooltip from 'react-tooltip';
import { FiEdit2, FiLoader, FiPlus } from 'react-icons/fi';
import './styles.css';

// Import Components
import AdminDesign from '~/components/Admin';
import Loading from '~/components/Default/Loading';
import BillBox from '~/components/Boxs/Bill';
import { Button } from '~/components/Parts/Forms';
import { useSingleBills } from './useSingle';

const Bills: React.FC = () => {
    const {
        ready,
        onChange,
        file,
        uploadFile,
        bills,
        delBill,
        openModalBill,
        setReady,
        isAdmin
    } = useSingleBills();

    return (
        <AdminDesign pageTitle="Contas de Energia - Clientes Energizou" title="Contas de Energia" classMain="bills">
            {
                ready ? <>
                    <div className="buttons">
                        <input id='file' name='file' type='file' onChange={onChange} ref={file} />

                        <Button
                            type="button"
                            func={() => {
                                if (file && file.current) file.current.value='';
                                file && file.current?.click();
                            }}
                            text={uploadFile ? 'Enviando...' : 'Enviar conta'}
                            icon={uploadFile ? <FiLoader size={12} /> : <FiPlus size={10} />}
                            className={`box ${uploadFile ? 'load' : 'edit'}`}
                            disabled={uploadFile ? true : false}
                        />

                        { isAdmin &&
                            <Button
                                type="button"
                                func={() => {openModalBill()}}
                                text='Cadastrar fatura'
                                icon={<FiEdit2 size={10} />}
                                className='box manual-registration'
                                disabled={uploadFile ? true : false}
                            />
                        }
                    </div>

                    <div className={`content${!ready ? ' loading' : ''}${bills.length===0 ? ' alert' : ''}`}>
                        {
                            bills.length>0 ?
                            bills.map((billData: any) => {
                                return (
                                    <BillBox
                                        key={`box_${billData.id}`}
                                        id={billData.id}
                                        provider={billData.provider}
                                        modality={billData.tariff_modality}
                                        date={billData.reference_date}
                                        path={billData.path}
                                        earq_id={billData.earq_id}
                                        status={billData.status}
                                        signed={billData.signed}
                                        delBill={delBill}
                                        setReady={setReady}
                                        bill={billData}
                                    />
                                )
                            }) :
                            <>Nenhuma conta de energia localizada</>
                        }
                    </div>

                    <ReactTooltip
                        id="tooltip_bills_page"
                        place="top"
                        effect="solid"
                        type="info"
                    />
                </> : <Loading />
            }

        </AdminDesign>
    );
}

export default Bills;